export enum AlertTypes {
    Temperature = 'temperature',
    HighPressure = 'highPressure',
    LowPressure = 'lowPressure',
    TemperaturePrediction = 'temperaturePrediction'
}

export enum CRUD {
    EMPTY = '',
    ADD = 'add',
    DELETE = 'delete',
    CLONE = 'clone',
    EDIT = 'edit',
    REMOVE = 'remove',
    REPLACE = 'replace',
    MOUNT = 'mount',
    UNMOUNT = 'unmount',
    RETURN_TO_RECOMMENDED = 'return_to_recommended'
}

export const MAX_FILE_SIZE = 20971520;

export const FILE_SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export const DRAG_AND_DROP_VALID_TYPES = [
    'image/bmp',
    'image/gif',
    'image/x-icon',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'audio/wave',
    'audio/wav',
    'audio/ogg',
    'video/ogg',
    'application/ogg',
    'video/mp4',
    'audio/mpeg',
    'text/plain',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/x-zip-compressed',
    'application/x-tar',
    'application/x-gzip'
];

export enum ROLES {
    ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_TECHNICAL_USER = 'ROLE_TECHNICAL_USER',
    ROLE_BASE_USER = 'ROLE_BASE_USER',
    ROLE_TYRE_BAY_USER = 'ROLE_TYRE_BAY_USER',
    ROLE_TECHNICAL_ADMIN = 'ROLE_TECHNICAL_ADMIN',
    ROLE_ENGINEERING = 'ROLE_ENGINEERING'
}

export enum SensorStatusEnum {
    FAULT = 'fault',
    SUSPICIOUS = 'suspicious',
    ACTIVE = 'active'
}

export enum ViewMode {
    GRID = 'grid',
    LIST = 'list'
}

export enum FittingReason {
    NEW_INSTALLATION = 'New installation',
    FAULTY_REPLACEMENT = 'Faulty replacement',
    SECONDARY_SENSOR = 'Secondary sensor'
}

export enum UnmountReason {
    REMOVED_TO_STOCK = 'Removed to stock',
    END_OF_LIFE = 'End of life',
    REMOVING_FAULTY = 'Removing faulty'
}

export const MUI_X_LICENSE_KEY =
    '769b6a82dca54a3ebb30db315c7cb05dTz03NTIxOCxFPTE3MjY5OTQ1NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,20}$/;

export const FooterText = '© Bridgestone iTrack';

export const MaxYear = 2099;
export const MinYear = 1900;

export const NotValue = '---';

export const NO_ARIA_LABEL_ALLOWED = undefined;
