import React from 'react';
import {
    PressureCellMode,
    TempAndPressListRowProps,
    ValueTypes
} from './TemperaturePressureTablePressureTemperatureReadings.type';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Wrapper } from 'helpers/wrapper';
import { TableCell, Tooltip, Typography } from '@mui/material';
import {
    AlertValue,
    ContainerPressureValue,
    ExclamationIcon,
    Over24hOldIcon,
    PressureValue,
    WheelTableCell,
    OldTemperaturePredictionIcon
} from './TemperaturePressureTablePressureTemperatureReadings.style';
import { useTranslation } from 'react-i18next';
import { TyreTempPressReport, Wheels } from 'models/Tyre.type';
import { SensorStatusEnum } from 'variables';
import { DateTime, Interval } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme, ThemeMode } from 'states/global/Theme';
import { COLOR_GREY, WHITE } from 'components/Ui/colors';
import { useQuery } from '@tanstack/react-query';
import CustomerAPI from 'api/Customer';
import { UserInfo } from 'states/global/User';
import { CustomerExtendedModel } from 'models/Customer.type';
import {
    TemperaturePredictionMenuAtom,
    WheelPrediction
} from 'components/Tyre/TemperaturePredictionPopover/TemperaturePredictionPopover.atom';
import { ReactComponent as ExternalIcon } from 'assets/svg/externalSensorIcon.svg';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { CustomerQueryKeys } from 'models/Customer.type';
import TemperaturePredictionPeriodIcon from 'components/Mixs/TemperaturePredictionPeriodIcon';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { TableViewTypeActionsEnum } from 'components/Tyre/TemperaturePressureTable/TemperaturePressureTable.type';

const customerAPI = new CustomerAPI();

const TemperaturePressureTablePressureTemperatureReadings: React.FC<TempAndPressListRowProps> = (
    props
): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { fromUTCtoUserTimezone } = useConverter();
    const { fromServerToUserUnit, displayUserUnits, convertType } = useConverter();
    const theme = useRecoilValue(Theme);
    const setTemperaturePredictionActionMenuAtom = useSetRecoilState(TemperaturePredictionMenuAtom);
    const customerSettings = useRecoilValue(CustomerSettings);

    const { data } = useQuery(
        [CustomerQueryKeys.getById, userInfo.user?.customer.id || 12],
        () =>
            customerAPI.getById<CustomerExtendedModel>({
                apiProject: '',
                id: userInfo.user?.customer.id || 12
            }),
        { staleTime: cacheTimeToMilliseconds(30, 'minutes'), cacheTime: cacheTimeToMilliseconds(30, 'minutes') }
    );

    const displayPressureValue = (
        type = 'cold',
        pressure: number | string | 0 | null = 0,
        alertLevel: null | number = 0,
        show = true
    ): JSX.Element | null => {
        return show ? (
            <PressureValue type={type}>
                {' '}
                <AlertValue $level={alertLevel ?? 0}>{pressure}</AlertValue>
            </PressureValue>
        ) : null;
    };

    const addTooltipToElement = (element, title, placement, shouldAdd): JSX.Element => {
        let refElement: JSX.Element = element;
        if (shouldAdd) {
            refElement = (
                <Tooltip title={title} aria-label={title} placement={placement} disableInteractive>
                    {element}
                </Tooltip>
            );
        }
        return refElement;
    };

    const filterWheelPredictionValues = (wheels) => {
        return wheels.filter((wheel) => wheel?.wheelPrediction != undefined);
    };

    const handleClick = (event, wheels): void => {
        setTemperaturePredictionActionMenuAtom({
            isOpen: true,
            data: {
                vehicleName: props.vehicle.name,
                vehicleId: props.vehicle.id,
                counterActionsExists: props?.vehicle?.counterAction?.counterActionIds.length > 0,
                wheelPredictions: [
                    ...filterWheelPredictionValues(wheels).map((wheel) => {
                        return {
                            ...wheel?.wheelPrediction,
                            position: wheel.customPosition,
                            levelAlert:
                                wheel?.wheelPrediction && wheel.temperature >= wheel.wheelPrediction.criticalTemperature
                                    ? 3
                                    : wheel?.wheelPrediction?.notificationEventLevel === 3
                                    ? 3
                                    : 2
                        };
                    })
                ]
            },
            positionX: event.clientX,
            positionY: event.clientY
        });
    };

    const over24hOldIcon: JSX.Element = <Over24hOldIcon icon={['far', 'stopwatch']} fontSize={20} fixedWidth />;

    const getWheelTd = (
        faulty: boolean,
        value: string | JSX.Element | null | number = null,
        alert: number | null | undefined = 0,
        isExternal: boolean,
        classes = '',
        styles = {},
        lastValidatedTempPredOutOfPeriod?: string | null,
        predictionTemperature?: boolean,
        valueType?,
        sensorId?: number | null
    ): JSX.Element => {
        return (
            <TableCell
                className={`alert-${alert || 0} ${classes}`}
                style={
                    valueType == ValueTypes.TEMPERATURE && value
                        ? { ...styles, cursor: 'pointer', position: 'relative' }
                        : { ...styles, position: 'relative' }
                }
                align='center'
            >
                {isExternal && (
                    <ExternalIcon
                        style={{
                            width: '11px',
                            height: '11px',
                            fill: theme.mode === ThemeMode.light ? '#0000008a' : WHITE
                        }}
                    />
                )}

                <div>{value}</div>
                <div>
                    {lastValidatedTempPredOutOfPeriod && sensorId && (
                        <OldTemperaturePredictionIcon styleNumber={value ? 0 : 1}>
                            <TemperaturePredictionPeriodIcon
                                time={lastValidatedTempPredOutOfPeriod}
                                predictionTemperature={predictionTemperature || false}
                                tempAndPressPage
                            />
                        </OldTemperaturePredictionIcon>
                    )}
                    {faulty && <ExclamationIcon>!</ExclamationIcon>}
                </div>
            </TableCell>
        );
    };

    const getWheelAlertTd = (
        level: WheelPrediction['notificationEventLevel'],
        faulty: boolean,
        value: string | JSX.Element | null | number = null,
        remainingTimeRaw: string,
        styles = {},
        lastValidatedTempPredOutOfPeriod: string | null,
        predictionTemperature: boolean,
        predictionTemperatureThresholdLevel2: TyreTempPressReport['predictionTemperatureThresholdLevel2'],
        temperature: TyreTempPressReport['wheels'][0]['temperature'],
        criticalTemperature: WheelPrediction['criticalTemperature']
    ): JSX.Element => {
        let remainingTime = Number(remainingTimeRaw?.split(':')[0]) || 0;
        let percentage =
            (remainingTime < 2 &&
                ((120 - Number(remainingTimeRaw?.split(':')[1]) - (remainingTime == 1 ? 60 : 0)) / 120) * 100) ||
            0;

        // map alert for prediction
        // 1 -> 2
        // 2 -> 3
        // 3 -> blinking
        const getActualLevel = () => {
            if (level === undefined) {
                return -1; // default callback to trigger no coloring
            }
            if (level === 0) {
                return 0;
            }
            if (level === 1) {
                return 2;
            }

            if (level === 2 && customerSettings.temperature_prediction_workflow === 1) {
                if ((temperature ?? 0) < criticalTemperature + (predictionTemperatureThresholdLevel2 ?? 0)) {
                    return 31; // keep border and empty bg
                }
            }
            if (level >= 2) {
                return 3;
            }
        };

        let coloringLevel = getActualLevel(); // for coloring

        const thresholdReached = level === 3;

        if (customerSettings.temperature_prediction_workflow === 1) {
            if (coloringLevel === 3) {
                percentage = 100; // fill bg
            }
        }

        return (
            <WheelTableCell
                style={styles}
                align='center'
                $percentage={percentage}
                $level={coloringLevel}
                $thresholdReached={thresholdReached}
                $isWorkflow1={customerSettings.temperature_prediction_workflow === 1}
            >
                {value}
                <div>
                    <UiIcon
                        icon={['fas', 'chart-mixed-up-circle-currency']}
                        style={{ width: '15px', height: '14px' }}
                        height='14px'
                        width='15px'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: theme?.mode,
                            light: COLOR_GREY,
                            dark: WHITE
                        })}
                    />
                    <OldTemperaturePredictionIcon styleNumber={1}>
                        <TemperaturePredictionPeriodIcon
                            time={lastValidatedTempPredOutOfPeriod}
                            predictionTemperature={predictionTemperature || false}
                            tempAndPressPage
                        />
                    </OldTemperaturePredictionIcon>
                </div>

                {faulty && (
                    <UiIcon
                        icon={['fas', 'exclamation']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: theme?.mode,
                            light: COLOR_GREY,
                            dark: WHITE
                        })}
                    />
                )}
            </WheelTableCell>
        );
    };

    const getDiffToOptimal = (pressure = 0, isPressure = true, optimal = 0): string | null => {
        return isPressure ? (pressure - optimal).toFixed(1) : null;
    };

    const displaySignedNumber = (number = 0): string => {
        return (number > 0 ? '+' : '') + (number || 0);
    };

    const generateMissingWheels = (neededWheels = 0): Wheels[] => {
        return Array(neededWheels)
            .fill(null)
            .map((e, index) => ({
                id: index + 1000,
                customPosition: -1,
                notWheel: true,
                coldPressure: null,
                hotPressure: null,
                lastTemperaturePredictedAt: null,
                genericTemperatureAlert: true,
                predictionTemperature: 0
            }));
    };

    const isTransmissionOlderThan24h = (date?: Date | null): boolean => {
        let todayDate: DateTime = DateTime.local();
        let transmissionDiff: DateTime = DateTime.fromISO(date);
        const durationTime: DateTime = Interval.fromDateTimes(transmissionDiff, todayDate)
            .toDuration(['hours'])
            .toObject();

        if (!Object.keys(durationTime).length) {
            return false;
        }
        return durationTime.hours >= 24;
    };

    const isTransmissionOlderThan30m = (date?: Date | null): boolean => {
        let todayDate: DateTime = DateTime.local();
        let transmissionDiff: DateTime = DateTime.fromISO(date);

        const durationTime: DateTime = Interval.fromDateTimes(transmissionDiff, todayDate)
            .toDuration(['minutes'])
            .toObject();

        if (!Object.keys(durationTime).length) {
            return false;
        }
        return durationTime.minutes >= 30;
    };

    const createPressureCellMode = (wheel: Wheels, hideCold = false): JSX.Element => {
        const modes: PressureCellMode = {
            cold: null,
            cold_opt: null,
            diff: null,
            hot: <></>,
            hot_opt: <></>,
            all: <></>
        };
        const coldPressure: string | number | null =
            wheel.coldPressure &&
            fromServerToUserUnit({ type: convertType.pressure, value: wheel.coldPressure, fixed: 1 });
        const hotPressure: string | number | null =
            wheel.hotPressure &&
            fromServerToUserUnit({ type: convertType.pressure, value: wheel.hotPressure, fixed: 1 });
        const optimalPressure: string | number | undefined =
            wheel.pressureOptimal !== null && wheel.pressureOptimal !== undefined
                ? fromServerToUserUnit({ type: convertType.pressure, value: wheel.pressureOptimal, fixed: 1 })
                : fromServerToUserUnit({ type: convertType.pressure, value: wheel.defaultPressureOptimal, fixed: 1 });
        const isColdPressure: boolean = wheel.coldPressure !== null && !isNaN(wheel.coldPressure);
        const isHotPressure: boolean = wheel.hotPressure !== null && !isNaN(wheel.hotPressure);
        const notTransmitting = isTransmissionOlderThan30m(wheel.measuredAt);

        modes.cold = isColdPressure ? (
            hideCold ? (
                'x'
            ) : (
                <AlertValue $level={notTransmitting ? 4 : wheel.pressureLevel || 0}>{coldPressure}</AlertValue>
            )
        ) : null;
        modes.cold_opt = modes.cold;
        modes.hot = <AlertValue $level={notTransmitting ? 4 : wheel.pressureHotLevel || 0}>{hotPressure}</AlertValue>;
        modes.hot_opt = <span>{hotPressure}</span>;

        const showCold: boolean = hideCold ? !hideCold : isColdPressure;
        modes.all = (
            <ContainerPressureValue>
                {displayPressureValue('cold', coldPressure, wheel.pressureLevel, showCold)}
                {displayPressureValue('hot', hotPressure, wheel.pressureHotLevel, isHotPressure)}
            </ContainerPressureValue>
        );

        const diffColdOptimal: string | null = getDiffToOptimal(
            parseFloat(`${coldPressure}`),
            isColdPressure,
            parseFloat(`${optimalPressure}`)
        );
        const title = (
            <>
                {`${translate('t.optimal')}: `}
                <strong>
                    {optimalPressure} {displayUserUnits.pressure}
                </strong>
            </>
        );
        modes.diff = isColdPressure ? (
            hideCold ? (
                'x'
            ) : optimalPressure ? (
                diffColdOptimal && (
                    <Tooltip title={title} placement='top' disableInteractive>
                        <span>{displaySignedNumber(+diffColdOptimal)}</span>
                    </Tooltip>
                )
            ) : (
                <Typography variant='h6' component='p'>
                    !
                </Typography>
            )
        ) : null;

        return props.tableDisplay && modes[props.tableDisplay];
    };

    const createWheelCellsTemperatureValue = (
        isOlderThan24h,
        isTemperature,
        wheel
    ): string | JSX.Element | null | number => {
        const notTransmitting = isTransmissionOlderThan30m(wheel.measuredAt);
        let wheelPredictionValue = 0;

        if (wheel.wheelPrediction) {
            wheelPredictionValue = wheel.wheelPrediction.notificationEventLevel + 1;
            if (wheelPredictionValue > 3) wheelPredictionValue = 3;
        }

        return isOlderThan24h ? (
            over24hOldIcon
        ) : isTemperature ? (
            !data?.externalTemperatureEnabled && wheel.sensorTypeId === 2 ? (
                'x'
            ) : (
                <AlertValue
                    $level={notTransmitting ? 4 : wheelPredictionValue || wheel.temperatureLevel || 0}
                    $whiteText={
                        customerSettings.temperature_prediction_workflow === 1 &&
                        wheel.wheelPrediction &&
                        wheel.wheelPrediction.notificationEventLevel === 2
                    }
                >
                    {fromServerToUserUnit({ type: convertType.temperature, value: wheel.temperature ?? 0, fixed: 1 })}
                </AlertValue>
            )
        ) : null;
    };

    const createWheelCellsPressureValue = (isOlderThan24h, wheel, hideCold): JSX.Element => {
        return isOlderThan24h ? over24hOldIcon : createPressureCellMode(wheel, hideCold);
    };

    const createWheelCellsTdClasses = (wheel): string => {
        let tdClasses = '';
        if (wheel.notWheel) {
            tdClasses = tdClasses.concat(' not-wheel');
        }

        if (isTransmissionOlderThan30m(wheel.measuredAt)) {
            tdClasses = tdClasses.concat(' not-transmitting');
        }

        return tdClasses;
    };

    const getOptimalPressure = (wheel): string | number | null | undefined => {
        let optimalPressureRaw: number | null | undefined = wheel.defaultPressureOptimal;
        if (props.tableDisplay === TableViewTypeActionsEnum.HOT_OPT && wheel.hotPressureOptimal) {
            optimalPressureRaw = wheel.hotPressureOptimal;
        }
        if (props.tableDisplay === TableViewTypeActionsEnum.COLD_OPT && wheel.pressureOptimal) {
            optimalPressureRaw = wheel.pressureOptimal;
        }
        if (!wheel.status) {
            optimalPressureRaw = null;
        }
        const optimalPressure: string | number | null | undefined =
            optimalPressureRaw && isNaN(optimalPressureRaw)
                ? optimalPressureRaw
                : optimalPressureRaw &&
                  fromServerToUserUnit({ type: convertType.pressure, value: optimalPressureRaw, fixed: 1 });

        return optimalPressure;
    };

    const getHighestAlert = (wheel, tableDisplay) => {
        if (tableDisplay === TableViewTypeActionsEnum.COLD || tableDisplay === TableViewTypeActionsEnum.COLD_OPT) {
            return wheel.pressureLevel ?? 0;
        }
        if (tableDisplay === TableViewTypeActionsEnum.HOT || tableDisplay === TableViewTypeActionsEnum.HOT_OPT) {
            return wheel.pressureHotLevel ?? 0;
        }
        return Math.max(wheel.pressureLevel ?? 0, wheel.pressureHotLevel ?? 0);
    };

    const createPressureTd = (wheel, faulty, pressureValue, highestAlert, tdClasses, time, existAnyValue) =>
        addTooltipToElement(
            getWheelTd(
                faulty,
                pressureValue,
                highestAlert,
                wheel.sensorTypeId === 2,
                tdClasses,
                undefined,
                undefined,
                undefined,
                undefined,
                wheel.sensorId
            ),
            time,
            'bottom',
            existAnyValue
        );

    const createTemperatureTd = (wheel, faulty, temperatureValue, tdClasses, vehicle) =>
        wheel?.wheelPrediction
            ? getWheelAlertTd(
                  wheel?.wheelPrediction?.notificationEventLevel,
                  faulty,
                  temperatureValue,
                  wheel.wheelPrediction?.remainingHours as string,
                  { padding: '8px 12px' },
                  wheel.lastTemperaturePredictedAt,
                  vehicle.predictionTemperature,
                  vehicle.predictionTemperatureThresholdLevel2,
                  wheel.temperature,
                  wheel.wheelPrediction?.criticalTemperature as number
              )
            : getWheelTd(
                  faulty,
                  temperatureValue,
                  wheel.temperatureLevel,
                  wheel.sensorTypeId === 2,
                  tdClasses,
                  { padding: '8px 12px' },
                  wheel.lastTemperaturePredictedAt,
                  vehicle.predictionTemperature,
                  ValueTypes.TEMPERATURE,
                  wheel.sensorId
              );

    const wheelCells = (wheels: Wheels[] = [], maximumWheels = 0, hideCold = false): JSX.Element[] => {
        const neededWheels = maximumWheels - wheels.length;
        wheels = wheels.concat(generateMissingWheels(neededWheels));

        return wheels.map((wheel, index) => {
            const highestAlert = getHighestAlert(wheel, props.tableDisplay);
            const isOlderThan24h = isTransmissionOlderThan24h(wheel.measuredAt);
            const time = wheel?.measuredAt && fromUTCtoUserTimezone({ date: wheel.measuredAt, format: 'dateTime' });
            const existAnyValue = wheel.temperature || wheel.coldPressure || wheel.hotPressure;
            const isTemperature = wheel.temperature && wheel.temperature !== null && !isNaN(wheel.temperature);

            const temperatureValue = createWheelCellsTemperatureValue(isOlderThan24h, isTemperature, wheel);
            const pressureValue = createWheelCellsPressureValue(isOlderThan24h, wheel, hideCold);
            const faulty = wheel.status === SensorStatusEnum.FAULT || wheel.status === SensorStatusEnum.SUSPICIOUS;
            const tdClasses = createWheelCellsTdClasses(wheel);

            const pressureTd = createPressureTd(
                wheel,
                faulty,
                pressureValue,
                highestAlert,
                tdClasses,
                time,
                existAnyValue
            );
            const temperatureTd = props.containsRecomValues
                ? getWheelTd(
                      faulty,
                      getOptimalPressure(wheel),
                      wheel?.wheelPrediction?.notificationEventLevel,
                      wheel.sensorTypeId === 2,
                      tdClasses,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      wheel.sensorId
                  )
                : addTooltipToElement(
                      createTemperatureTd(wheel, faulty, temperatureValue, tdClasses, props.vehicle),
                      time,
                      'bottom',
                      existAnyValue
                  );

            return (
                <React.Fragment key={index}>
                    {pressureTd}
                    {React.cloneElement(temperatureTd, {
                        onClick: (e) => {
                            filterWheelPredictionValues(wheels).length > 0 && handleClick(e, wheels);
                        }
                    })}
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
            {wheelCells(
                props.vehicle.wheels.filter((wheel) => +wheel.customPosition <= props.maxPosition),
                props.maxPosition,
                !props.vehicle.internalSensorCount
            )}
        </React.Fragment>
    );
};

export default Wrapper(TemperaturePressureTablePressureTemperatureReadings);
