import React, { useMemo } from 'react';
import { Column, TyreAdornment, TyreFilterIcon } from '../TyreManager.style';
import { TyreModel, TyreQueryKeys } from 'models/Tyre.type';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TyreStateAtom } from '../TyreManager.atom';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import TyreAPI from 'api/Tyre';
import { TyreStateDispatcher } from '../TyreManager.reducer';
import { InputAdornment, MenuItem, Tooltip } from '@mui/material';
import useModal from 'components/CustomHooks/ModalHook';
import AddTyre from '../TyreActionPopup/AddTyre';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import { SUCCESS, SUCCESSDARK, WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import TyreFilterComponent from 'components/Tyre/TyreFilter';
import { TyreFilter } from 'components/Tyre/TyreFilter/TyreFilter.atom';
import UiButton from 'components/Ui/Components/UiButton/UiButton';

const tyreAPI = new TyreAPI();

const TyreLazyLoading = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [tyreState, setTyreState] = useRecoilState(TyreStateAtom);
    const dispatcher = TyreStateDispatcher(tyreState, setTyreState);
    const { setModalState } = useModal(1);
    const themeMode = useRecoilValue(Theme);
    const [tyreFilter, setTyreFilter] = useRecoilState(TyreFilter);

    const appliedTyreFilters = useMemo(() => {
        const filters: {
            tyreManufacturerId?: number;
            tyreModelId?: number;
        } = {};
        if (tyreFilter.tyreManufacturer?.value) {
            filters.tyreManufacturerId = tyreFilter.tyreManufacturer.value;
        }
        if (tyreFilter.tyreModel?.value) {
            filters.tyreModelId = tyreFilter.tyreModel.value;
        }
        return filters;
    }, [tyreFilter]);

    return (
        <Column $first>
            <LazyloadingAutocomplete
                id='tyre-serial-numbers'
                queryId={TyreQueryKeys.getTable}
                query={tyreAPI.getTable}
                optionKey='tyreSerialNumber'
                optionValue='id'
                preselectedValue={{
                    value: tyreState.newTyre.id,
                    label: tyreState.newTyre.tyreSerialNumber || '',
                    origItem: tyreState.newTyre
                }}
                label={translate('t.tyres')}
                width={200}
                size='small'
                onValueChange={(value?: number, label?: string, origItem?: TyreModel) => {
                    dispatcher({
                        id: origItem?.id || tyreState.originalTyre.id,
                        vehicleName: origItem?.vehicleName || '',
                        customPosition: origItem?.customPosition || tyreState.originalTyre.customPosition,
                        tyreSerialNumber: origItem?.tyreSerialNumber || tyreState.originalTyre.tyreSerialNumber
                    });
                }}
                groupBy={{
                    key: 'vehicleName',
                    defaultGroup: 'No Vehicle'
                }}
                queryFilter={appliedTyreFilters}
                querySortBy={[
                    {
                        id: 'vehicleName',
                        desc: false
                    }
                ]}
                cacheTime={0}
                InputProps={{
                    endAdornment: (
                        <TyreAdornment position='start'>
                            <UiIconButton
                                onClick={() => {
                                    setModalState({
                                        isOpen: true,
                                        content: <TyreFilterComponent />,
                                        width: 550,
                                        height: 250,
                                        leftTitle: translate('t.tyre_filter'),
                                        hideCancelButton: true,
                                        buttons: (
                                            <>
                                                <UiButton
                                                    variant='contained'
                                                    onClick={() => {
                                                        setModalState({ isOpen: false });
                                                    }}
                                                >
                                                    {translate('t.apply')}
                                                </UiButton>
                                            </>
                                        )
                                    });
                                }}
                                size='small'
                                testid='TyreManager-ShowFilter-Button'
                            >
                                <UiIcon
                                    icon={['fas', 'bars-filter']}
                                    fontSize={14}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: themeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                            </UiIconButton>
                            {(tyreFilter.tyreManufacturer || tyreFilter.tyreModel) && (
                                <Tooltip title='Applied filter'>
                                    <span>
                                        <TyreFilterIcon
                                            icon={['fas', 'circle-check']}
                                            fontSize={14}
                                            fixedWidth
                                            color={applyStyleByMode({
                                                theme: themeMode?.mode,
                                                light: SUCCESS,
                                                dark: SUCCESSDARK
                                            })}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </TyreAdornment>
                    )
                }}
                placeholder={'S/N'}
                addTopElement={(value: string) => {
                    return value ? (
                        <MenuItem
                            title={translate('t.add_tyre')}
                            style={{ backgroundColor: '#ececec' }}
                            disabled={!value || value.includes('/')}
                            onClick={() => {
                                setModalState({
                                    isOpen: true,
                                    content: <AddTyre value={value} />,
                                    width: 550,
                                    height: 260,
                                    leftTitle: translate('t.add_tyre')
                                });
                            }}
                        >
                            <UiIcon icon={['fas', 'circle-plus']} style={{ paddingRight: '5px' }} color='green' />{' '}
                            {value}
                        </MenuItem>
                    ) : (
                        <></>
                    );
                }}
            />
        </Column>
    );
};

export default TyreLazyLoading;
