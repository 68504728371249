import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders,
    AxiosResponse,
    InternalAxiosRequestConfig
} from 'axios';
import * as Popup from 'components/Popup';
import { authToken } from '../authentication';
import { getCustomerToken, getFromLocalStorage } from '../index';

export let source = axios.CancelToken.source();

export const setNewCancelToken = () => {
    source = axios.CancelToken.source();
};

const redirectStatusMessages: Array<string> = ['Invalid JWT Token', 'Expired JWT Token', 'JWT Token not found'];

const validateParams = (params?: unknown) => {
    if (params) {
        const isInvalidParam = (param: unknown) =>
            typeof param == 'undefined' || (typeof param === 'number' && isNaN(param)) || param === 'Invalid date';
        Object.keys(params).forEach((k) => {
            if (isInvalidParam(params[k])) {
                delete params[k];
            }
        });
    }
};

export class Api {
    instance!: AxiosInstance;
    showError404 = true;
    controller: AbortController;

    constructor(config: AxiosRequestConfig) {
        this.setHTTPClient(config);
        this.SUCCESS.bind(this);
        this.ERROR.bind(this);
        this.controller = new AbortController();
    }

    setHTTPClient = (config: AxiosRequestConfig): void => {
        this.instance = axios.create(config);

        this.instance.interceptors.request.use(
            (value: InternalAxiosRequestConfig): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
                validateParams(value.params);
                return {
                    ...value,
                    headers: {
                        ...value.headers,
                        Authorization: `Bearer ${this.getToken()}`,
                        ...(getCustomerToken() && { 'X-Customer-Token': getCustomerToken() })
                    } as AxiosRequestHeaders
                };
            },
            (error: AxiosError<unknown>) => {
                return Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (param: AxiosResponse) => {
                return param;
            },
            (error: any) => {
                if (axios.isCancel(error)) {
                    return false;
                }
                if (!error?.response) {
                    getFromLocalStorage<boolean>('isOnline') !== false &&
                        Popup.Error({
                            message: error.response?.statusText || '',
                            url: error?.config?.url || '',
                            status: 500
                        });
                    return Promise.reject(error);
                }

                if (axios.isAxiosError(error)) {
                    const { message, error_code } = error.response.data;
                    if (message) {
                        if (typeof message === 'string') {
                            if (error.response?.status === 404 && !this.showError404) {
                                return false;
                            }
                            getFromLocalStorage<boolean>('isOnline') !== false &&
                                Popup.Errors({
                                    text: message
                                });
                        } else if (Array.isArray(message)) {
                            getFromLocalStorage<boolean>('isOnline') !== false &&
                                Popup.Errors({
                                    text: message,
                                    multipleMessage: true
                                });
                        } else {
                            getFromLocalStorage<boolean>('isOnline') !== false &&
                                Popup.Errors({
                                    text: `Error: ${error.response.status ?? 'unknown'}`
                                });
                        }
                    }

                    if (
                        error.response?.status === 401 &&
                        redirectStatusMessages.includes(error.response?.data.message)
                    ) {
                        location.href = `${process.env.PUBLIC_URL}/login`;
                    }

                    if (error.response.status === 403 && error_code === 1001) {
                        location.reload();
                    }

                    return Promise.reject(error);
                }
            }
        );
    };

    getToken = (): string => {
        const token: string[] = document.cookie.split(';').filter((c) => c.includes('authToken'));
        if (token.length && token[0]) {
            return token[0].split('=')[1];
        }
        return authToken();
    };

    HEAD = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.head(url, config);
    };

    OPTIONS = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.options(url, config);
    };

    REQUEST = <T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> => {
        return this.instance.request(config);
    };

    GET = <T, R = AxiosResponse<T>>(url: string, params?: AxiosRequestConfig, baseURL?: string): Promise<R> => {
        return this.instance.get(url, {
            params,
            cancelToken: source.token,
            baseURL: baseURL,
            signal: this.controller.signal
        });
    };

    GET_FILE = <T, R = AxiosResponse<T>>(url: string, params?: AxiosRequestConfig): Promise<R> => {
        return this.instance.get(url, params);
    };

    CREATE = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.post(url, data, config);
    };

    UPDATE = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.put(url, data, config);
    };

    PATCH = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.patch(url, data, config);
    };

    DELETE = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.instance.delete(url, config);
    };

    SUCCESS<T>(response: AxiosResponse<T>): T {
        return response.data;
    }

    ERROR<T>(error: AxiosError<T>): void {
        throw error;
    }
}
