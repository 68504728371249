// SIZES
export const SIZE_BUTTON = 'small';
export const SIZE_BUTTON_BIG_FONT = 'large';
export const SIZE_INPUT = 'small';
export const SIZE_TEXT = `0.86rem`;
export const SIZE_TEXT_VARIANT = (addPixel: number) =>
    `${(parseFloat((addPixel * 0.06).toFixed(3)) + 0.75).toFixed(3)}rem`;
export const SIZE_TITLE = `0.96rem`;
export const SIZE_SUBTITLE = `0.91rem`;

// Big Font / easyMode
export const FONT_BIG_TEXT_SIZE = '0.99rem';
export const FONT_BIG_TITLE_SIZE = '1.3rem';
export const FONT_BIG_SUBTITLE_SIZE = '1.1rem';

// VARIANTS
export const VARIANT_CHIP = 'filled';
export const VARIANT_BUTTON = 'contained';
export const VARIANT_ALERT = 'filled';

export const DEFAULT_SOUND = 'alarm3';
