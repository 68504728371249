import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const UiInputSearchContent = styled.div<{ width?: number; minWidth?: number }>`
    .inputSearchAutoWidth {
        width: ${(props) => `${props.width ?? 200}px`};
        height: 35px;
        transition: width 0.7s;
    }

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        .inputSearchAutoWidth {
            width: ${(props) => `${props.width ?? 95}px`};
        }
    }

    .MuiOutlinedInput-adornedStart {
        padding-left: 5px;
    }
`;

export const FormControlCustom = styled(FormControl)<{ width?: number; $small?: boolean }>`
    width: ${(props) => `${props.width ?? 200}px`};
    height: ${(props) => (props.$small ? '26px' : '36px')};
    border-radius: 4px;

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        width: 95px;
    }

    &.MuiFormControl-root {
        & input {
            ${(props) =>
                props.$small &&
                `
                padding-top: 4px !important;
                padding-bottom: 4px !important;
            `};
            ${({ $small }) => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `
                    font-size: ${$small ? common.textSize : common.subTitleSize};
                `;
            }}
        }
        & fieldset {
            height: ${(props) => (props.$small ? '28px' : '40px')};
            margin-top: 1px;

            legend {
                margin: 0px;
            }
        }
        & .muisvgicon-root: {
            font-size: 1em !important;
        }
    }
`;
