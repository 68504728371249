import React from 'react';
import { TempAndPressListHeaderContent } from './TemperaturePressureTableListHeader.view';
import { TempAndPressListHeaderProps } from './TemperaturePressureTableListHeader.type';
import { Wrapper } from 'helpers/wrapper';
import { CustomTableCell, TableCellSubHeaderSticky } from './TemperaturePressureTableListHeader.style';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { TableViewTypeActionsEnum } from '../TemperaturePressureTable/TemperaturePressureTable.type';

const TempAndPressListHeader: React.FC<TempAndPressListHeaderProps> = (props): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);

    const getSorting = (column: string): 'desc' | 'asc' | null => {
        if (props.orderDirection && props.orderDirection.column === column) {
            return props.orderDirection.order;
        }
        return null;
    };

    const tempPressLabel = (index): JSX.Element => {
        const getLabel = props.tableDisplay === TableViewTypeActionsEnum.COLD_OPT ? 'TCP' : 'THP';
        let secondColumnLabel: string = props.containsRecomValues
            ? getLabel
            : `°${userInfo.user?.userSetting.temperatureUnit}`;

        return (
            <React.Fragment key={index}>
                <TableCellSubHeaderSticky $isPortalOld={false} align='center' $tableDisplay={props.tableDisplay ?? ''}>
                    {userInfo.user?.userSetting.pressureUnit}
                </TableCellSubHeaderSticky>
                <TableCellSubHeaderSticky $isPortalOld={false} align='center'>
                    {secondColumnLabel}
                </TableCellSubHeaderSticky>
            </React.Fragment>
        );
    };

    const wheelLabel = (index): JSX.Element => {
        return (
            <CustomTableCell align='center' style={{ minWidth: 50 }} colSpan={2} key={index}>
                Pos. {index + 1}
            </CustomTableCell>
        );
    };

    return (
        <TempAndPressListHeaderContent
            data-testid='TempAndPressListHeader-testid'
            {...props}
            getSorting={getSorting}
            tempPressLabel={tempPressLabel}
            wheelLabel={wheelLabel}
        />
    );
};

export default Wrapper(TempAndPressListHeader);
