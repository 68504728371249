import { AttachedSensor } from './Sensors.type';
//import { TyreModel } from './Tyre.type';
import { VehicleModel } from './Vehicle.type';
import { VehiclePredictionType } from './TpmsAlerts.type';
import { TyreManufacturerModel } from './TyreManufacturer.type';

type Wheel = {
    id: number;
    position: number;
    customPosition: number;
    vehicle: VehicleModel | null;
    lastTemperaturePredictedAt?: string | null;
};

/*type Wheel = {
    createdAt: string;
    customPosition: number;
    id: number;
    measuredAt: string | null;
    position: number;
} & Pick<TyreModel, 'id' | 'tyreSerialNumber'>;
 // need to verify if Wheel entity has Tyre props
*/

export type WheelCodebook = {
    codeBook: Wheel[];
};

export type TyreDetail = {
    wheel: Wheel & {
        tyreId?: number;
        tyreSerialNumber?: string;
        lastTemperaturePredictedAt: string | null;
        tyreManufacturer: TyreManufacturerModel;
    }; // check if should be there
    attachedSensors: AttachedSensor[];
    hasInternalSensor: boolean;
};

type Notification = {
    type: string;
    level: number;
    type2: string;
    id: number;
};

export type SensorAlert = {
    id: number;
    position: number;
    notification: Notification;
    wheelPrediction?: VehiclePredictionType;
};

// temp - will be Model
export type SensorMake = {
    id: number;
    description: string | null;
    fullVoltage: number | null;
    criticalVoltage: number | null;
};

// temp - will be Model
export type SensorStats = {
    id: number;
    battery: number | null;
    measuredAt: string | null;
};

export type TyreDetailResponse = TyreDetail[];

export type SwapPositionResponse = {
    wheelSwapFrom: WheelSwapType[];
    wheelSwapTo: WheelSwapType[];
};

type WheelSwapType = {
    id: number;
    position: number;
    customPosition: number;
    vehicle: {
        id: number;
        name: string;
    };
};

export enum WheelQueryKeys {
    getAll = 'wheel',
    getCodeBook = 'wheel-getCodeBook',
    getTyresDetail = 'wheel-getTyresDetail'
}

export default Wheel;
