import React, { useEffect } from 'react';
import { AttachedSensorsProps } from './TyreDetails.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import {
    Alert,
    ExternalSensorIcon,
    SensorFaultyStatusIcon,
    SensorBatteryStatusIcon,
    SensorHexNumber
} from './TyreDetails.style';
import { VehicleDetailsTyresAtom } from '../VehicleDetailsTyres/VehicleDetailsTyres.atom';
import { SelectedSensorAtom } from './SelectedSensor.atom';
import { ROLES, SensorStatusEnum } from 'variables';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme, ThemeMode } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { SensorAlert } from 'models/Wheel.type';
import { applyStyleByMode } from 'helpers';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';

const AttachedSensors: React.FC<AttachedSensorsProps> = ({ attachedSensors, wheelPosition }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [selectedSensor, setSelectedSensor] = useRecoilState(SelectedSensorAtom(wheelPosition));
    const vehicleDetailsTyresAtom = useRecoilValue(VehicleDetailsTyresAtom);
    const userInfo = useRecoilValue(UserInfo);
    const theme = useRecoilValue(Theme);

    useEffect(() => {
        setSelectedSensor(attachedSensors[0] || null);
    }, [attachedSensors[0], setSelectedSensor]);

    if (
        !attachedSensors.length &&
        checkRole(userInfo.user, [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_ADMIN
        ])
    ) {
        return (
            <Link
                href='#'
                onClick={(event) => {
                    event.preventDefault();
                }}
                variant='body2'
                data-testid='AttachedSensors-AddSensor-Button'
            >
                {translate('t.add_sensor')}
            </Link>
        );
    }

    const getMaxAlert = (sensorAlerts: SensorAlert[], sensorAlert: number | null) => {
        let alertLevel = 0;
        for (let i = 0; i < sensorAlerts.length; i++) {
            if (sensorAlerts[i]?.wheelPrediction?.notificationEventLevel) {
                const wheelPredLevel = (sensorAlerts[i]?.wheelPrediction?.notificationEventLevel || 0) > 1 ? 3 : 2;
                if (wheelPredLevel > alertLevel) alertLevel = wheelPredLevel;
            }
        }

        return sensorAlert && sensorAlert > alertLevel ? sensorAlert : alertLevel;
    };

    return (
        <>
            {attachedSensors.map((sensor, index) => {
                const isSelectedSensor: boolean = sensor.sensor.id === selectedSensor?.sensor.id;
                const hasLowBattery =
                    sensor.sensor.sensorStats.length &&
                    sensor.sensor.sensorStats[0].battery &&
                    sensor.sensor.sensorMake?.criticalVoltage
                        ? sensor.sensor.sensorStats[0].battery <= sensor.sensor.sensorMake?.criticalVoltage
                        : false;

                return (
                    <Alert
                        key={index}
                        alertLevel={
                            sensor?.sensorLog?.measuredAt ? getMaxAlert(sensor.sensorAlerts, sensor.sensorAlert) : 0
                        }
                        onClick={(event) => {
                            if (!isSelectedSensor) {
                                event.stopPropagation();
                                setSelectedSensor(sensor);
                            }
                        }}
                        data-testid={`VehicleStatus-ShowSensorData-${sensor.sensor.serialNumberHex}-Button`}
                    >
                        <SensorHexNumber $isSelected={isSelectedSensor}>
                            {sensor.sensor.serialNumberHex}
                        </SensorHexNumber>
                        {sensor.sensor.sensorType?.id === 2 && (
                            <ExternalSensorIcon
                                title={translate('t.external_sensor')}
                                style={{
                                    fill: theme.mode === ThemeMode.light ? '#0000008a' : WHITE
                                }}
                            />
                        )}
                        {(sensor.sensor.sensorInfo?.status === SensorStatusEnum.FAULT ||
                            sensor.sensor.sensorInfo?.status === SensorStatusEnum.SUSPICIOUS) && (
                            <SensorFaultyStatusIcon title={translate('t.exclamation_icon')}>!</SensorFaultyStatusIcon>
                        )}
                        {hasLowBattery && (
                            <SensorBatteryStatusIcon
                                title={translate('t.low_battery_voltage')}
                                icon={['fas', 'battery-low']}
                                data-testid='sensor-low-battery-icon'
                            />
                        )}
                        {!vehicleDetailsTyresAtom.secondSensorAlerts && sensor.sensor.priority === 2 && (
                            <UiIcon
                                icon={['fas', 'bell-slash']}
                                title={translate('t.secondary_sensor_notificatons_disabled')}
                                color={applyStyleByMode({
                                    theme: theme?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        )}
                    </Alert>
                );
            })}
        </>
    );
};

export default AttachedSensors;
