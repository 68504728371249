import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BasicRow, CustomerSettingsContainer, EditRowMode, ItemsAmbTepm, KeyValue } from './CustomerSettings.style';
import InputAdornment from '@mui/material/InputAdornment';
import { CustomerSettingsViewProps } from './CustomerSettings.type';
import { useTranslation } from 'react-i18next';
import { Box, Grid, TextField, Typography } from '@mui/material';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { COLOR_GREY_3, ERROR, SUCCESS, WHITE } from 'components/Ui/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { faCheck, faGear, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { CustomerSettingEditModeAtom, SelectedCustomerSettingValue } from 'states/component/CustomerSettings';
import {
    CustomerSetting,
    CustomerSettingAll,
    CustomerSettingType,
    CustomerSettingsModel,
    OptionLabels
} from 'models/CustomerSettings.type';
import { UserUnits } from 'states/global/User';
import { CRUD } from 'variables';
import CustomerSettingInputs from '../CustomeSettingInputs';
import UiWidget from 'components/Ui/Components/UiWidget';
import { applyStyleByMode } from 'helpers';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { snakeToCamel } from 'helpers/converter/text';
import UiInputSearch from 'components/Ui/Components/UiInputSearch/UiInputSearch';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';
import UiPopper from 'components/Ui/UiPopper/UiPopper';
import UiCheckbox from 'components/Ui/Components/UiCheckbox/UiCheckbox';
import { CustomerSettingsState } from './CustomerSetings.atom';
import CustomerSettingsLoader from './CustomerSettingsLoader';

const recommendedValue = (type: CustomerSettingType, row: CustomerSettingAll): JSX.Element => {
    switch (type) {
        case CustomerSettingType.BOOLEAN: {
            return <>{row?.defaultValue === true ? 'Enabled' : 'Disabled'}</>;
        }

        case CustomerSettingType.LIST: {
            return <>{row?.options && Object.keys(row?.options).length && row?.options[row?.value]}</>;
        }

        default:
            return <>{`${row?.defaultValue}`}</>;
    }
};

const EditRow: React.FC<{
    value: unknown;
    translationKey: string;
    customerSetting: CustomerSettingsModel['settings'][0];
    type: CustomerSettingType | undefined;
    customerSettingsPopup: (
        actionType: CRUD.EDIT | CRUD.ADD | CRUD.REMOVE | CRUD.RETURN_TO_RECOMMENDED,
        value: string | number | boolean | null | undefined | [] | unknown,
        customerSettingType: CustomerSettingsModel['settings'][0] | undefined
    ) => void;
    isSubRow: boolean;
    lastRow: boolean;
}> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const setEditMode = useSetRecoilState(CustomerSettingEditModeAtom);
    const { dateTimeFormat } = useConverter();
    const [value, setValue] = useState<unknown>(props.value);
    const [selectedSettingValue, setSelectedSettingValue] = useRecoilState(SelectedCustomerSettingValue);

    const actionBtns = useCallback((): JSX.Element => {
        const isRemove: boolean = props.customerSetting.defaultValue == props.customerSetting.value;
        return (
            <Box data-testid='CustomerSettings-ActionBtns'>
                <UiButton
                    size='small'
                    skin={SUCCESS}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                        setEditMode([props.customerSetting.key]);
                        if (!props.customerSetting.customValue) {
                            props.customerSettingsPopup(CRUD.ADD, value, props.customerSetting);
                        } else {
                            props.customerSettingsPopup(CRUD.EDIT, value, props.customerSetting);
                        }
                    }}
                    testid={`${props.customerSetting.key}SaveButton`}
                    style={{
                        margin: '5px'
                    }}
                    disabled={
                        props.customerSetting?.type === CustomerSettingType.DATETIME
                            ? (typeof selectedSettingValue === 'string'
                                  ? !DateTime.fromFormat(
                                        selectedSettingValue,
                                        dateTimeFormat('dateTime', !!props.customerSetting.format?.includes('s'))
                                    ).isValid
                                  : !(selectedSettingValue as DateTime)?.isValid) || selectedSettingValue === undefined
                            : props.customerSetting?.key === 'ac_set_hotspot'
                            ? (selectedSettingValue as [])?.length === 0
                            : false
                    }
                >
                    {translate('t.save')}
                </UiButton>

                {!props.customerSetting.required && props.customerSetting.customValue ? (
                    <UiButton
                        size='small'
                        color='error'
                        variant='contained'
                        testid={`${props.customerSetting.key}${isRemove ? 'RemoveButton' : 'RecommendedButton'}`}
                        skin={ERROR}
                        onClick={() => {
                            const setValue = isRemove ? (value as any) : props?.customerSetting?.defaultValue;

                            props.customerSettingsPopup(
                                isRemove ? CRUD.REMOVE : CRUD.RETURN_TO_RECOMMENDED,
                                setValue,
                                props.customerSetting
                            );
                        }}
                    >
                        {isRemove ? translate('t.remove') : translate('t.return_to_recommended')}
                    </UiButton>
                ) : (
                    <></>
                )}

                {!props.customerSetting.customValue ? (
                    <UiButton
                        size='small'
                        color='success'
                        variant='contained'
                        testid={`${props.customerSetting.key}EditButton`}
                        skin={SUCCESS}
                        onClick={() => {
                            setEditMode([props.customerSetting.key]);
                            props.customerSettingsPopup(
                                CRUD.ADD,
                                props.customerSetting.defaultValue,
                                props.customerSetting
                            );
                        }}
                    >
                        {translate('t.enable_with_recommended_value')}
                    </UiButton>
                ) : (
                    <></>
                )}
            </Box>
        );
    }, [props.translationKey, value, selectedSettingValue]);

    useEffect(() => {
        setSelectedSettingValue(value as string | number | boolean | null | undefined | []);
    }, [value]);

    return (
        <EditRowMode
            $isSubRow={props.isSubRow}
            $lastRow={props.lastRow}
            sx={{
                borderLeft: props.customerSetting.children ? 'solid #cbcbcb 6px' : '',
                borderBottom: props.customerSetting.children ? 'none' : ''
            }}
        >
            <TableCell
                width={150}
                style={{
                    maxWidth: 150,
                    overflow: 'hidden'
                }}
                data-testid={`Row${snakeToCamel(props.translationKey)}Label`}
            >
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ mt: -0.5 }}>
                        <UiPopper
                            titlePopper={translate('t.description')}
                            content={
                                <Box sx={{ maxWidth: 500 }}>
                                    <h4>{translate(`p.${props.customerSetting.key}_description`)}</h4>
                                </Box>
                            }
                        />
                    </Box>
                    <Box sx={{ display: 'block' }}>
                        <Typography>{translate(`t.${props.translationKey}`)}</Typography>
                        <Typography variant='caption' sx={{ color: '#898989' }}>
                            {translate('t.recommended_value')}:{' '}
                            {recommendedValue(props.customerSetting.type, props.customerSetting)}
                        </Typography>
                    </Box>
                </Box>
            </TableCell>
            <TableCell width={170} data-testid={`Row${snakeToCamel(props.translationKey)}Value`}>
                <CustomerSettingInputs
                    actionType={CRUD.EDIT}
                    value={value}
                    setValue={setValue}
                    type={props.type}
                    settingOptions={props.customerSetting}
                />
            </TableCell>
            <TableCell width={210} data-testid={`Row${snakeToCamel(props.translationKey)}Actions`}>
                {actionBtns()}
            </TableCell>
        </EditRowMode>
    );
};

export const CustomerSettingsContent: React.FC<CustomerSettingsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const NO_EDITABLE = ['mine_operation_start'];
    const userUnits = useRecoilValue(UserUnits);
    const currentMonth = 7;
    const { dateTimeFormat } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const [customerSettingsFilters, setCustomerSettingsFilters] = useRecoilState(CustomerSettingsState);

    const customerSettingsRow = (row: CustomerSetting, index: number, subRow = false, lastRow = false): JSX.Element => {
        return (
            <BasicRow
                key={index}
                $isSubRow={subRow}
                $lastRow={lastRow}
                sx={{ borderLeft: row.children ? `solid ${COLOR_GREY_3} 7px !important` : '' }}
            >
                <TableCell
                    width={150}
                    key={index}
                    style={{
                        maxWidth: 150,
                        overflow: 'hidden'
                    }}
                >
                    <KeyValue>
                        <Box sx={{ mt: -0.5 }}>
                            <UiPopper
                                titlePopper={translate('t.description')}
                                content={
                                    <Box sx={{ maxWidth: 500 }}>
                                        <h4>{translate(`p.${row.key}_description`)}</h4>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box sx={{ display: 'block' }}>
                            <Typography>{translate(`t.${row.key}`)} </Typography>
                            {row.defaultValue !== null ? (
                                <Typography variant='caption' sx={{ color: '#898989' }}>
                                    {translate('t.recommended_value')}: {recommendedValue(row.type, row)}
                                </Typography>
                            ) : (
                                <></>
                            )}
                            {row.deprecated && (
                                <Typography variant='body2' sx={{ color: '#898989' }} fontSize='0.7rem'>
                                    *{translate('t.deprecated')}
                                </Typography>
                            )}
                        </Box>
                    </KeyValue>
                </TableCell>
                <TableCell
                    width={170}
                    style={{
                        cursor: 'auto',
                        width: 170,
                        overflowWrap: 'anywhere'
                    }}
                >
                    {NoEditValue(row.type, row)}
                </TableCell>
                <TableCell width={210}>
                    <></>
                </TableCell>
            </BasicRow>
        );
    };

    const NoEditValue = (type: CustomerSettingType, row: CustomerSettingAll): JSX.Element => {
        switch (type) {
            case CustomerSettingType.BOOLEAN:
                return <UiIcon icon={row?.value ? faCheck : faTimes} style={{ padding: 0 }} />;
            case CustomerSettingType.LIST:
                return row.options ? row.options[row.value] : row.value;
            case CustomerSettingType.MULTIPLE_LIST:
                return OptionLabels[row?.value] || row?.value || translate('t.none');
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                return row?.value
                    ?.filter((month, index) => index === currentMonth - 1)
                    ?.map((val, index) => (
                        <TextField
                            data-testid='AmbientTemperature-TextField-testid'
                            label={`${translate(`t.month${currentMonth}`)}`}
                            type='number'
                            size='small'
                            key={index}
                            InputLabelProps={{ shrink: true }}
                            value={val || null}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        data-testid='AmbientTemperature-InputAdornment-testid'
                                    >
                                        °{userUnits.temperature.toUpperCase()}
                                    </InputAdornment>
                                )
                            }}
                            style={{
                                marginRight: '10px',
                                marginTop: '10px'
                            }}
                        />
                    ));
            case CustomerSettingType.MAP_AREA:
                return row?.value?.map((val, index) => (
                    <TextField
                        data-testid='MapArea-TextField-testid'
                        label={`${translate('t.coordinates')} ${index + 1}`}
                        type='number'
                        size='small'
                        key={index}
                        InputLabelProps={{ shrink: true }}
                        value={val || null}
                        InputProps={{
                            readOnly: true
                        }}
                        style={{
                            marginRight: '10px',
                            marginTop: '10px'
                        }}
                    />
                ));
            case CustomerSettingType.DATETIME: {
                return <>{row?.value?.toFormat(dateTimeFormat('dateTime', !!row.format?.includes('s')))}</>;
            }
            case CustomerSettingType.TEXT: {
                return (
                    <TextField
                        data-testid={`${row.key}-TextField-testid`}
                        label={translate(`t.${row.key}`)}
                        size='small'
                        multiline
                        minRows={8}
                        maxRows={10}
                        InputLabelProps={{ shrink: true }}
                        value={OptionLabels[row?.value] || row?.value}
                        InputProps={{
                            readOnly: true
                        }}
                        style={{
                            marginRight: '10px',
                            marginTop: '10px'
                        }}
                    />
                );
            }
            default:
                return OptionLabels[row?.value] || row?.value;
        }
    };

    const unusedSettingsInitValue = (
        value: unknown,
        customerSetting: CustomerSettingsModel['settings'][0] | undefined
    ): JSX.Element | string | JSX.Element[] | unknown => {
        switch (customerSetting?.type) {
            case CustomerSettingType.BOOLEAN:
                return false;
            case CustomerSettingType.LIST:
            case CustomerSettingType.TEXT:
            case CustomerSettingType.COMMAND:
            case CustomerSettingType.MULTIPLE_LIST:
                return '';
            case CustomerSettingType.MAP_AREA:
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                if (!value || value === 'null') {
                    return value;
                }
                return (value as []).map(() => 0);
            case CustomerSettingType.DATETIME:
                return null;
            case CustomerSettingType.INTEGER:
            case CustomerSettingType.NUMBER:
                return 0;
            default:
                return value;
        }
    };

    const customerSettingsTableBody = useCallback(() => {
        if (!props.typedCustomerSettings || props.typedCustomerSettings.settings.length < 1) {
            return (
                <Box sx={{ p: 3, textAlign: 'center', minHeight: '456px' }}>
                    <Box sx={{ p: 3 }}>
                        <Typography variant='h5' data-testid='CustomerSettingsList_message_not_data'>
                            {translate('t.there_no_data')}
                        </Typography>
                    </Box>
                </Box>
            );
        }

        return props.typedCustomerSettings?.settings.map((row, index) => {
            return (
                <Box key={row.key}>
                    <ItemsAmbTepm $hasEditMode={true} height={row.key === 'ambient_temperature' ? '700px' : '48px'}>
                        {NO_EDITABLE.includes(row.key) ? (
                            customerSettingsRow(row, index, Boolean(row.children))
                        ) : (
                            <EditRow
                                customerSetting={row}
                                translationKey={row.key}
                                type={row.type}
                                value={!row.customValue ? unusedSettingsInitValue(row.defaultValue, row) : row.value}
                                customerSettingsPopup={props.customerSettingsPopup}
                                isSubRow={false}
                                lastRow={false}
                            />
                        )}

                        {row.children ? (
                            <Box sx={{ display: 'table-row-group' }}>
                                {row.children.map((subRow, subIndex) => {
                                    const isSubRow: boolean = row.children?.length === subIndex + 1;

                                    return NO_EDITABLE.includes(row.key) ? (
                                        customerSettingsRow(subRow, subIndex, true, isSubRow)
                                    ) : (
                                        <EditRow
                                            key={subIndex}
                                            customerSetting={subRow}
                                            translationKey={subRow.key}
                                            type={subRow.type}
                                            value={
                                                !subRow.customValue
                                                    ? unusedSettingsInitValue(subRow.defaultValue, subRow)
                                                    : subRow.value
                                            }
                                            customerSettingsPopup={props.customerSettingsPopup}
                                            isSubRow={true}
                                            lastRow={isSubRow}
                                        />
                                    );
                                })}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </ItemsAmbTepm>
                </Box>
            );
        });
    }, [props.typedCustomerSettings, props.isLoading]);

    return (
        <CustomerSettingsContainer data-testid='CustomerSettingsContent'>
            <UiWidget
                title={translate('t.customer_settings')}
                loading={false}
                avatar={
                    <UiIcon
                        icon={faGear}
                        size='lg'
                        fixedWidth
                        data-testid='customerSettingsAvatar'
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                padding='10px'
            >
                <Grid data-testid='CustomerSettingsContainer' container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container xs={12} md={12} lg={12}>
                            <UiInputSearch
                                inputSearch={{
                                    valueInputSearch: customerSettingsFilters.valueSearch,
                                    setValueInputSearch: props.searchSettings
                                }}
                                getValueInputSearch={(text: string) => props.searchSettings(text)}
                                widthInputSearchInit={250}
                                widthInputSearchActive={400}
                                searchByEnter={false}
                                placeholder={translate('t.search_for_settings')}
                                $testid='Temp&Press-InputFilterByVehicleNameAndHubId-Input'
                                minWidth={200}
                            />

                            <Box sx={{ margin: '7px 5px 5px 10px' }}>
                                <UiCheckbox
                                    checked={customerSettingsFilters.showAll}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setCustomerSettingsFilters((state) => ({
                                            ...state,
                                            showAll: !!e.target.checked,
                                            unusedSettings: false,
                                            showUsedSettings: !e.target.checked ? true : false
                                        }));
                                    }}
                                    name='recommended_values_checkbox'
                                    color='primary'
                                    label={translate('t.show_all')}
                                />
                                <UiCheckbox
                                    checked={customerSettingsFilters.unusedSettings}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setCustomerSettingsFilters((state) => ({
                                            ...state,
                                            unusedSettings: !!e.target.checked,
                                            showAll: false,
                                            showUsedSettings: !e.target.checked ? true : false
                                        }));
                                    }}
                                    name='unused_settings_checkbox'
                                    color='primary'
                                    label={translate('t.unused_settings')}
                                />
                                <UiCheckbox
                                    checked={customerSettingsFilters.showUsedSettings}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setCustomerSettingsFilters((state) => ({
                                            ...state,
                                            showUsedSettings: !!e.target.checked,
                                            showAll: false,
                                            unusedSettings: false
                                        }));
                                    }}
                                    name='custom_values_checkbox'
                                    color='primary'
                                    label={translate('t.show_used_settings')}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ paddingRight: '5px', mt: 1.5 }}>
                        <TableContainer component={Box}>
                            <Table size='small' aria-label='a dense table'>
                                <TableBody>
                                    <UiScroll maxHeight='calc(100vh - 290px)'>
                                        <Box sx={{ borderTop: 'thin solid #c0c0c0', minHeight: 'calc(100vh - 290px)' }}>
                                            {!props.isLoading ? (
                                                customerSettingsTableBody()
                                            ) : (
                                                <CustomerSettingsLoader />
                                            )}
                                        </Box>
                                    </UiScroll>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </UiWidget>
        </CustomerSettingsContainer>
    );
};
