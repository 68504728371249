import Version from 'api/Version';
import { VersionAPIModel } from 'models/Version.type';
import { atom, selector } from 'recoil';
const activeVersionAPI = new Version();

type Bradcrumb = {
    title: string;
    icon: JSX.Element;
    path?: string;
    key: string;
};

export const Breadcrumb = atom({
    key: 'breadcrumb',
    default: [] as Bradcrumb[] | []
});

export const ApiInfo = selector({
    key: 'ApiInfo',
    get: async ({ get }) => {
        try {
            const apiInfo = await activeVersionAPI.get<VersionAPIModel>({ apiProject: undefined });
            return apiInfo;
        } catch (error) {
            false;
        }
        return { env: 'unknown', version: 'unknown' };
    }
});
