import React, { useEffect, useRef } from 'react';
import ModalButton from 'components/Ui/Components/UiButton/UiButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { useTranslation } from 'react-i18next';
import { DeviceModel } from 'models/Device.type';
import Menu from 'components/Ui/Components/UiMenu/UiMenu';
import { useMutation } from '@tanstack/react-query';
import { InfoIconContainer, LabelIconInfoContainer, Text, StyledFormControl } from './AdministrationTable.style';
import Device from 'api/Device';
import AddHub from '../AddHub/AddHub';
import { Success } from 'components/Popup/Popup';
import { applyStyleByMode } from 'helpers';
import { UserInfo } from 'states/global/User';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { InputLabel, OutlinedInput, TextField, Tooltip, MenuItem, FormControl, Autocomplete } from '@mui/material';
import Select from '@mui/material/Select';
import { COLOR_GREY, ERROR, WHITE } from 'components/Ui/colors';
import ShowWifiPassword from '../ShowWifiPassword/ShowWifiPassword';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { Theme } from 'states/global/Theme';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { AdministrationTableProps } from './AdministrationTable.type';
import { AdministrationTableContent } from './AdministrationTable.view';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { Wrapper } from 'helpers/wrapper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';

const AdministrationTable: React.FC<AdministrationTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryTableFn = useRecoilValue(QueryFn(`administrationHub-QueryFn`));
    const administrationHubApi = new Device();
    const refAddHub = useRef<HTMLButtonElement>(null);
    const userInfo = useRecoilValue(UserInfo);
    const ThemeMode = useRecoilValue(Theme);
    const { fromUTCtoUserTimezone } = useConverter();

    const updateModal = (isOpen?: boolean): void => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: <ModalButton skin='success'>{<CircularProgress size='1.75em' />}</ModalButton>,
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const { mutate: unassignAdministrationHubMutate, isLoading: unassignAdministrationHubLoading } = useMutation(
        administrationHubApi.unassignAdministrationHub,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.hub_successfully_removed')
                });
            },
            onSettled: () => {
                updateModal(false);
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            }
        }
    );

    const handleModalRemove = (id: number): void => {
        const contentModalDelete = {
            id: 'RemoveHubActions',
            leftTitle: `${translate('t.remove_hub')}`,
            content: (
                <Text style={{ margin: '20px 0 0 0 ' }} variant='h6'>
                    {translate('p.are_you_sure_to_delete')}
                </Text>
            ),
            buttons: (
                <ModalButton
                    skin='success'
                    color='error'
                    variant='contained'
                    onClick={() => {
                        unassignAdministrationHubMutate(id);
                    }}
                >
                    {unassignAdministrationHubLoading ? <CircularProgress size='1.75em' /> : translate('t.remove')}
                </ModalButton>
            ),
            width: 400,
            height: 100,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalDelete,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalDelete,
                isOpen: true
            }
        });
    };

    const { mutate: addAdministrationHubMutate, isLoading: addAdministrationHubLoading } = useMutation(
        administrationHubApi.addAdministrationHub,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.hub_created_successfully')
                });
            },
            onSettled: () => {
                updateModal(false);
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            }
        }
    );

    const handleModalAdd = (): void => {
        const contentModalAdd = {
            id: 'AddHubActions',
            leftTitle: `${translate('t.add_hub')}`,
            rightTitle: '',
            content: <AddHub refAddHub={refAddHub} addHubMutation={addAdministrationHubMutate} />,
            buttons: (
                <ModalButton
                    skin='success'
                    color='primary'
                    variant='contained'
                    testid='AddHub-button'
                    onClick={() => {
                        refAddHub?.current && refAddHub?.current.click();
                    }}
                >
                    {addAdministrationHubLoading ? <CircularProgress size='1.75em' /> : translate('t.add')}
                </ModalButton>
            ),
            width: 640,
            height: 270,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalAdd,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                isOpen: true
            }
        });
    };

    const handleShowPassword = (deviceId: number): void => {
        const contentModalDelete = {
            id: 'showWifiSecretKey',
            leftTitle: `${translate('t.hub_wifi_hotspot')}`,
            rightTitle: `${translate('t.hub_id')}: ${deviceId}`,
            content: <ShowWifiPassword deviceId={deviceId} />,
            buttons: <></>,
            width: 800,
            height: 380,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalDelete,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalDelete,
                isOpen: true
            }
        });
    };

    const columns: Column<DeviceModel>[] = [
        {
            Header: translate('t.actions'),
            width: 88,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <span>
                    <Menu
                        items={[
                            {
                                title: (
                                    <UiLink
                                        $padding='0'
                                        content={translate('t.hub_detail')}
                                        url={`/device/${props.row.original.id}`}
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: COLOR_GREY,
                                            dark: WHITE
                                        })}
                                    />
                                ),
                                value: props.value
                            },

                            props.row.original.vehicle
                                ? {
                                      title: (
                                          <UiLink
                                              $padding='0'
                                              content={translate('t.vehicle_detail')}
                                              url={`/vehicle-status?vehicle=${props.row.original.vehicle.id}`}
                                              color={applyStyleByMode({
                                                  theme: ThemeMode?.mode,
                                                  light: COLOR_GREY,
                                                  dark: WHITE
                                              })}
                                          />
                                      ),
                                      value: props.value
                                  }
                                : {
                                      title: '',
                                      none: true,
                                      value: ''
                                  },

                            {
                                title: translate('t.remove'),
                                value: props.value,
                                action: () => {
                                    handleModalRemove(props.row.original.id);
                                }
                            },

                            checkRole(userInfo.user, [
                                ROLES.ROLE_ADMIN,
                                ROLES.ROLE_TECHNICAL_ADMIN,
                                ROLES.ROLE_SUPER_ADMIN
                            ])
                                ? {
                                      title: translate('t.hub_wifi_hotspot'),
                                      value: props.value,
                                      action: () => {
                                          handleShowPassword(props.row.original.id);
                                      }
                                  }
                                : {
                                      title: '',
                                      none: true,
                                      value: ''
                                  }
                        ]}
                        rootEl={
                            <Button
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                                title={translate('t.actions')}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    />
                </span>
            )
        },
        {
            Header: 'ID',
            accessor: (row: DeviceModel) => row.id,
            id: 'id',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => (
                <UiLink $padding='0' content={`${props.row.original.id}`} url={`/device/${props.row.original.id}`} />
            )
        },
        {
            Header: '',
            disableSortBy: true,
            accessor: 'mounted',
            Filter: ({ column, setFilter, ...filterProps }) => {
                const options = [
                    { value: '1', label: 'assigned' },
                    { value: '0', label: 'unassigned' }
                ];
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <StyledFormControl variant='outlined' size='small'>
                        <Autocomplete
                            options={options}
                            getOptionLabel={(option) => translate(`t.${option.label}`)}
                            onChange={(_, newValue) => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.hubAssignmentStatus'),
                                          humanValue: translate(`t.${newValue.label}`),
                                          value: `${newValue.value}`
                                      };
                                setFilterValue(value);
                            }}
                            size='small'
                            defaultValue={options.find((element) => element.value === cellValue?.value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate('t.hubAssignmentStatus')}
                                    variant='outlined'
                                    name='mounted'
                                    value={cellValue?.value || null}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        />
                    </StyledFormControl>
                );
            }
        },
        {
            Header: translate('t.vehicle_name'),
            accessor: (row: DeviceModel) => row.vehicle?.name,
            id: 'vehicle.name',
            width: 120,
            height: 20,
            disableSortBy: true,
            Cell: (props: { row: { original: DeviceModel } }) =>
                props.row.original.vehicle?.id ? (
                    <UiLink
                        $padding='0'
                        content={`${props.row.original.vehicle?.name}`}
                        url={`/vehicle-status?vehicle=${props.row.original.vehicle.id}`}
                    />
                ) : (
                    <>-</>
                )
        },
        {
            Header: translate('t.hub_faulty_status'),
            accessor: 'faultyStatus',
            width: 150,
            height: 20,
            disableSortBy: true,
            Cell: (props: { row: { original: DeviceModel } }) => {
                return (
                    <>
                        {props.row.original.faultyStatus?.length ? (
                            <span
                                style={{
                                    color: applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: ERROR,
                                        dark: '#ff2d2d'
                                    })
                                }}
                            >
                                {props.row.original.faultyStatus
                                    ?.map((faultyStatus) =>
                                        translate(
                                            `t.${faultyStatus.status.statusName.toLowerCase().replaceAll(' ', '_')}`
                                        )
                                    )
                                    .join(', ')}
                            </span>
                        ) : (
                            <span>-</span>
                        )}
                    </>
                );
            },
            Filter: ({ setFilter, column, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small' style={{ width: '100%', margin: '4px 0 8px 0' }}>
                        <InputLabel htmlFor='verified-status-native-simple' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            variant='outlined'
                            label={<>{column.Header}</>}
                            value={cellValue?.value || ''}
                            onChange={(e) => {
                                let value: object | undefined = undefined;
                                const currentValue = e.target.value;

                                if (currentValue) {
                                    value = { name: column.Header, value: e.target.value };
                                }
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'verified-status',
                                id: 'verified-status-native-simple'
                            }}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                        >
                            <MenuItem value={''}>{translate('t.all')}</MenuItem>
                            <MenuItem value={`1;${translate('t.faulty')}`}>{translate('t.faulty')}</MenuItem>
                            <MenuItem value={`0;${translate('t.healty')}`}>{translate('t.healty')}</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.last_transmission'),
            accessor: (row: DeviceModel) => row.deviceInfo.lastTransmission,
            id: 'deviceInfo.lastTransmission',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => (
                <>
                    {fromUTCtoUserTimezone({
                        date: props.row.original.deviceInfo.lastTransmission,
                        format: 'dateTime',
                        displaySeconds: true
                    }) ?? '-'}
                </>
            ),
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='lastTransmissionFrom'
                            keyFilter='lastTransmissionFrom'
                            label={`${translate('t.last_transmission')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='lastTransmissionTo'
                            keyFilter='lastTransmissionTo'
                            label={`${translate('t.last_transmission')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: 'MAC',
            accessor: 'uuid',
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.uuid ?? '-'}</>
        },
        {
            Header: 'IMEI',
            accessor: 'imei',
            disableSortBy: true,
            width: 170,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => (
                <>{props.row.original.imei ? props.row.original.imei : '-'}</>
            )
        },
        {
            Header: 'IP',
            accessor: (row: DeviceModel) => row.deviceInfo.ip,
            id: 'deviceInfo.ip',
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.deviceInfo.ip ?? '-'}</>,
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFull, setFilterValue: setFilterValueFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'ipFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'ipFullText'
                });

                const { cellValue: cellValueNotFull, setFilterValue: setFilterValueNotFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'notIpFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'notIpFullText'
                });

                return (
                    <>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='ip-fulltext'
                            label={'IP'}
                            value={cellValueFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: 'IP',

                                          value: newValue.target.value
                                      };
                                setFilterValueFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='ip-nofulltext'
                            label={
                                <LabelIconInfoContainer>
                                    {translate('t.not_full_text', { value: 'IP' })}{' '}
                                    <Tooltip
                                        title={translate('p.not_full_text', {
                                            value: translate('t.not_full_text', { value: 'IP' })
                                        })}
                                        arrow
                                        placement='top-start'
                                    >
                                        <InfoIconContainer>
                                            <InfoOutlinedIcon />
                                        </InfoIconContainer>
                                    </Tooltip>{' '}
                                </LabelIconInfoContainer>
                            }
                            value={cellValueNotFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: translate('t.not_full_text', { value: 'IP' }),

                                          value: newValue.target.value
                                      };
                                setFilterValueNotFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.mask'),
            accessor: (row: DeviceModel) => row.deviceInfo.mask,
            id: 'deviceInfo.mask',
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.deviceInfo.mask ?? '-'}</>,
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFull, setFilterValue: setFilterValueFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'maskFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'maskFullText'
                });

                const { cellValue: cellValueNotFull, setFilterValue: setFilterValueNotFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'notMaskFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'notMaskFullText'
                });
                return (
                    <>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='mask-fulltext'
                            label={translate('t.mask')}
                            value={cellValueFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: translate('t.mask'),

                                          value: newValue.target.value
                                      };
                                setFilterValueFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='mask-nofulltext'
                            label={
                                <LabelIconInfoContainer>
                                    {translate('t.not_full_text', { value: translate('t.mask') })}{' '}
                                    <Tooltip
                                        title={translate('p.not_full_text', {
                                            value: translate('t.not_full_text', { value: translate('t.mask') })
                                        })}
                                        arrow
                                        placement='top-start'
                                    >
                                        <InfoIconContainer>
                                            <InfoOutlinedIcon />
                                        </InfoIconContainer>
                                    </Tooltip>{' '}
                                </LabelIconInfoContainer>
                            }
                            value={cellValueNotFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: translate('t.not_full_text', { value: translate('t.mask') }),

                                          value: newValue.target.value
                                      };
                                setFilterValueNotFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.gateway'),
            accessor: (row: DeviceModel) => row.deviceInfo.gateway,
            id: 'deviceInfo.gateway',
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.deviceInfo.gateway ?? '-'}</>,
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFull, setFilterValue: setFilterValueFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'gatewayFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'gatewayFullText'
                });

                const { cellValue: cellValueNotFull, setFilterValue: setFilterValueNotFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'notGatewayFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'notGatewayFullText'
                });
                return (
                    <>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='gateway-fulltext'
                            label={translate('t.gateway')}
                            value={cellValueFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: translate('t.gateway'),

                                          value: newValue.target.value
                                      };
                                setFilterValueFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='gateway-nofulltext'
                            label={
                                <LabelIconInfoContainer>
                                    {translate('t.not_full_text', { value: translate('t.gateway') })}{' '}
                                    <Tooltip
                                        title={translate('p.not_full_text', {
                                            value: translate('t.not_full_text', { value: translate('t.gateway') })
                                        })}
                                        arrow
                                        placement='top-start'
                                    >
                                        <InfoIconContainer>
                                            <InfoOutlinedIcon />
                                        </InfoIconContainer>
                                    </Tooltip>{' '}
                                </LabelIconInfoContainer>
                            }
                            value={cellValueNotFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: translate('t.not_full_text', { value: translate('t.gateway') }),

                                          value: newValue.target.value
                                      };
                                setFilterValueNotFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.hotspot_active'),
            accessor: (row: DeviceModel) => row.deviceInfo.hotspotActive,
            id: 'deviceInfo.hotspotActive',
            disableSortBy: true,
            disableFilters: true,
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => (
                <>
                    {props.row.original.deviceInfo.hotspotActive
                        ? translate('t.active')
                        : translate('t.inactive') ?? '-'}
                </>
            )
        },
        {
            Header: translate('t.wifi_band'),
            accessor: (row: DeviceModel) => row.deviceInfo.wifiBand,
            id: 'deviceInfo.wifiBand',
            disableSortBy: true,
            disableFilters: true,
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) =>
                props.row.original.deviceInfo.wifiBand ? <>{`${props.row.original.deviceInfo.wifiBand} GHz`}</> : <>-</>
        },
        {
            Header: translate('t.wifi_channel'),
            accessor: (row: DeviceModel) => row.deviceInfo.wifiChannel,
            id: 'deviceInfo.wifiChannel',
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => (
                <>{props.row.original.deviceInfo.wifiChannel ?? '-'}</>
            ),
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFull, setFilterValue: setFilterValueFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'wifiChannelFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'wifiChannelFullText'
                });

                const { cellValue: cellValueNotFull, setFilterValue: setFilterValueNotFull } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'notWifiChannelFullText')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'notWifiChannelFullText'
                });

                return (
                    <>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='ip-fulltext'
                            label={translate('t.wifi_channel')}
                            value={cellValueFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: translate('t.wifi_channel'),
                                          value: newValue.target.value
                                      };
                                setFilterValueFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='ip-nofulltext'
                            label={
                                <LabelIconInfoContainer>
                                    {translate('t.not_full_text', { value: translate('t.wifi_channel') })}{' '}
                                    <Tooltip
                                        title={translate('p.not_full_text', {
                                            value: translate('t.not_full_text', { value: translate('t.wifi_channel') })
                                        })}
                                        arrow
                                        placement='top-start'
                                    >
                                        <InfoIconContainer>
                                            <InfoOutlinedIcon />
                                        </InfoIconContainer>
                                    </Tooltip>{' '}
                                </LabelIconInfoContainer>
                            }
                            value={cellValueNotFull?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: translate('t.not_full_text', { value: translate('t.wifi_channel') }),

                                          value: newValue.target.value
                                      };
                                setFilterValueNotFull(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.service'),
            accessor: (row: DeviceModel) => row.deviceInfo.service,
            id: 'deviceInfo.service',
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.deviceInfo.service ?? '-'}</>
        },
        {
            Header: translate('t.tools'),
            accessor: (row: DeviceModel) => row.deviceInfo.tools,
            id: 'deviceInfo.tools',
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.deviceInfo.tools ?? '-'}</>
        },
        {
            Header: translate('t.revision'),
            accessor: 'deviceRevision',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original?.deviceRevision?.name || '-'}</>
        },
        {
            Header: translate('t.serial_number'),
            accessor: 'serialNumber',
            width: 140,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.serialNumber ?? '-'}</>
        },
        {
            Header: translate('t.emmc_wear_perc'),
            accessor: (row: DeviceModel) => row.deviceInfo.deviceEmmcStatus?.wearPerc,
            id: 'deviceInfo.deviceEmmcStatus.wearPerc',
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) =>
                props.row.original.deviceInfo.deviceEmmcStatus?.wearPerc ? (
                    <>{`${props.row.original.deviceInfo.deviceEmmcStatus?.wearPerc} %`}</>
                ) : (
                    <>-</>
                ),
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFrom, setFilterValue: setFilterValueFrom } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'deviceEmmcPercFrom')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'deviceEmmcPercFrom'
                });

                const { cellValue: cellValueTo, setFilterValue: setFilterValueTo } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'deviceEmmcPercTo')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'deviceEmmcPercTo'
                });
                return (
                    <>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='emmcWearPerc-from'
                            type='number'
                            label={`${translate('t.emmc_wear_perc')} ${translate('t.from')}`}
                            value={cellValueFrom?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: `${translate('t.emmc_wear_perc')} ${translate('t.from')}`,

                                          value: newValue.target.value
                                      };
                                (Number(newValue.target.value) <= 100 || !newValue.target.value.length) &&
                                    setFilterValueFrom(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='emmcWearPerc-to'
                            type='number'
                            label={`${translate('t.emmc_wear_perc')} ${translate('t.to')}`}
                            value={cellValueTo?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: `${translate('t.emmc_wear_perc')} ${translate('t.to')}`,

                                          value: newValue.target.value
                                      };
                                (Number(newValue.target.value) <= 100 || !newValue.target.value.length) &&
                                    setFilterValueTo(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.battery'),
            accessor: (row: DeviceModel) => row.deviceInfo.battery,
            id: 'deviceInfo.battery',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.deviceInfo.battery ?? '-'}</>,
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFrom, setFilterValue: setFilterValueFrom } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'batteryFrom')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'batteryFrom'
                });

                const { cellValue: cellValueTo, setFilterValue: setFilterValueTo } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'batteryTo')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'batteryTo'
                });
                return (
                    <>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='battery-from'
                            type='number'
                            label={`${translate('t.battery')} ${translate('t.from')}`}
                            value={cellValueFrom?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: `${translate('t.battery')} ${translate('t.from')}`,

                                          value: newValue.target.value
                                      };
                                setFilterValueFrom(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='battery-to'
                            type='number'
                            label={`${translate('t.battery')} ${translate('t.to')}`}
                            value={cellValueTo?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: `${translate('t.battery')} ${translate('t.to')}`,

                                          value: newValue.target.value
                                      };
                                setFilterValueTo(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.power_supply'),
            accessor: (row: DeviceModel) => row.deviceInfo.powerSource,
            id: 'deviceInfo.powerSource',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => (
                <>{props.row.original.deviceInfo.powerSource ?? '-'}</>
            ),
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFrom, setFilterValue: setFilterValueFrom } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'powerSourceFrom')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'powerSourceFrom'
                });

                const { cellValue: cellValueTo, setFilterValue: setFilterValueTo } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'powerSourceTo')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'powerSourceTo'
                });
                return (
                    <>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='powerSource-from'
                            type='number'
                            label={`${translate('t.power_supply')} ${translate('t.from')}`}
                            value={cellValueFrom?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: `${translate('t.power_supply')} ${translate('t.from')}`,

                                          value: newValue.target.value
                                      };
                                setFilterValueFrom(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            id='powerSource-to'
                            type='number'
                            label={`${translate('t.power_supply')} ${translate('t.to')}`}
                            value={cellValueTo?.value || ''}
                            onChange={(newValue) => {
                                const value = !newValue.target.value.length
                                    ? undefined
                                    : {
                                          name: `${translate('t.power_supply')} ${translate('t.to')}`,

                                          value: newValue.target.value
                                      };
                                setFilterValueTo(value);
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                style: {
                                    padding: '10.5px'
                                }
                            }}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.firmware'),
            accessor: (row: DeviceModel) => row.deviceInfo.fmw,
            id: 'deviceInfo.fmw',
            width: 150,
            height: 20,
            Cell: (props: { row: { original: DeviceModel } }) => <>{props.row.original.deviceInfo.fmw ?? '-'}</>
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'batteryFrom'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'batteryTo'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'powerSourceFrom'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'powerSourceTo'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'deviceEmmcPercFrom'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'deviceEmmcPercTo'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'ipFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'notIpFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'wifiChannelFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'notWifiChannelFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'maskFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'notMaskFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'gatewayFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'notGatewayFullText'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'lastTransmissionFrom'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'lastTransmissionTo'
        }
    ];

    useEffect(() => {
        updateModal();
    }, [unassignAdministrationHubLoading, addAdministrationHubLoading]);

    return (
        <AdministrationTableContent
            data-testid='AdministrationTable-testid'
            handleModalAdd={handleModalAdd}
            columns={columns}
        />
    );
};

export default Wrapper(AdministrationTable);
