import React, { useMemo } from 'react';
import {
    Container,
    UITableCell,
    NoVehicleIcon,
    StatusIcon,
    StyledTableRow,
    TdSubTable,
    UiHighPressureIcon,
    UiLowPressureIcon
} from './VehicleStatsRow.style';
import { VehicleStatsRowViewProps } from './VehicleStatsRow.type';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import HubConnectionIndicator from 'components/Vehicle/HubConnectionIndicator/HubConnectionIndicator';
import HubBatteryIndicator from 'components/Vehicle/HubBatteryIndicator/HubBatteryIndicator';
import { BESRank } from './BESRank';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { RED_ICON, SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { CustomerSettings } from 'states/global/CustomerSettings';
import {
    faChartMixedUpCircleCurrency,
    faThermometerThreeQuarters,
    faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { getImgSource } from 'helpers/image';
import VehicleStatsCollapseButton from 'components/Vehicle/VehicleStatsCollapseButton';
import VehicleStatsCollapseTable from 'components/Vehicle/VehicleStatsCollapseTable';
import { CollapseVehicleStatsRow } from 'components/Vehicle/VehicleStatsCollapseButton/VehicleStatsCollapseButton.atom';
import { FilterPeriod } from '../../VehicleStats.atom';
import GpsIndicator from 'components/Vehicle/VehicleStatusWidget/Components/GpsIndicator/GpsIndicator';
import { DateTime } from 'luxon';
import TemperaturePredictionPeriodIcon from 'components/Mixs/TemperaturePredictionPeriodIcon';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { NO_ARIA_LABEL_ALLOWED } from 'variables';

export const VehicleStatsRowContent: React.FC<VehicleStatsRowViewProps> = ({
    row,
    vehicleAlerts,
    alertDetailData,
    hasSystemAlert,
    showModalAlerts
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const { convertType, fromServerToUserUnit } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const theme = useRecoilValue(Theme);
    const setCollapsedVehicleRow = useSetRecoilState(CollapseVehicleStatsRow);
    const period = useRecoilValue(FilterPeriod);

    const collapseRow = () => {
        setCollapsedVehicleRow((current) => (current === row.id ? 0 : row.id));
    };

    const alertLevel = useMemo(() => {
        if (vehicleAlerts.temperaturePrediction) {
            if (row.alert === 1) {
                return 2;
            }
            if (row.alert > 1) {
                return 3;
            }
        }
        return row.alert;
    }, [row.alert]);

    return (
        <>
            <StyledTableRow data-testid={`vehicleStats-row-${row.id}`} alertlevel={alertLevel ?? 0}>
                <TableCell className='firstTd' data-testid={`vehicleStats-row-operated-hours-title-${row.id}`}>
                    <Container display='contents'>
                        <span>
                            <VehicleStatsCollapseButton testId={`expand-action-${row.id}`} vehicleId={row.id} />
                        </span>
                        {row.icon && getImgSource(`${row.icon}`) ? (
                            <img
                                alt={`${row.id}`}
                                src={getImgSource(`${row.icon}`)}
                                width={theme.easyReadingMode ? '30px' : '25px'}
                            />
                        ) : (
                            <NoVehicleIcon>
                                <UiIcon
                                    data-testid={`vehiclestats-noVehicleIcon-${row.id}`}
                                    icon={['fas', 'ban']}
                                    size='1x'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: SECONDARY_DARK_MODE,
                                        dark: WHITE
                                    })}
                                />
                            </NoVehicleIcon>
                        )}
                        <UiLink
                            $className='linkVehicleName'
                            color='#40FFA6'
                            content={row.name}
                            title={translate('t.click_see_vehicle')}
                            url={`/vehicle-status?vehicle=${row.id}`}
                            testId={`VehicleStats-VehicleName-${row.id}-link`}
                        />
                    </Container>
                </TableCell>
                <TableCell
                    align='justify'
                    onClick={collapseRow}
                    data-testid={`vehicleStats-row-operated-hours-HubConnectionIndicator-${row.id}`}
                >
                    <Container display='inline-flex'>
                        <StatusIcon>
                            <HubConnectionIndicator
                                lastTimeConnected={row.vehicleInfo.measuredAt as string}
                                connectionType={'wifi'}
                                testid={`vehiclestats-hubConnectionIndicator-${row.id}`}
                            />
                        </StatusIcon>
                        {row.maintenance && (
                            <Tooltip title={translate('t.vehicle_in_maintenance')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                                <StatusIcon $hide={!row.maintenance}>
                                    <UiIcon
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: SECONDARY_DARK_MODE,
                                            dark: WHITE
                                        })}
                                        data-testid={`vehiclestats-vehicleInMaintenanceIcon-${row.id}`}
                                        icon={['fas', 'wrench']}
                                        size='1x'
                                    />
                                </StatusIcon>
                            </Tooltip>
                        )}
                        <StatusIcon
                            onClick={(event) => {
                                if (row.deviceId || row.vehicleInfo.battery > -1)
                                    return showModalAlerts(event, row.id, 3);
                            }}
                            $hide={!row.deviceId || row.vehicleInfo.battery === -1}
                        >
                            <HubBatteryIndicator
                                battery={row.vehicleInfo.battery}
                                powerSource={row.vehicleInfo.powerSource}
                                testid={`vehiclestats-hubBatteryIndicator-${row.id}`}
                            />
                        </StatusIcon>
                        <Tooltip title={translate('t.vehicle_without_hub')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                            <StatusIcon $hide={!!row.deviceId}>
                                <UiIcon
                                    data-testid={`vehiclestats-vehicleWithoutHubIcon-${row.id}`}
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: SECONDARY_DARK_MODE,
                                        dark: WHITE
                                    })}
                                    icon={['fas', 'rectangle-xmark']}
                                    size='1x'
                                />
                            </StatusIcon>
                        </Tooltip>
                        <Tooltip title={translate('t.hub_shutdown')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                            <StatusIcon $hide={!row.shutdown}>
                                <UiIcon
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: SECONDARY_DARK_MODE,
                                        dark: WHITE
                                    })}
                                    data-testid={`vehiclestats-hubShutdownIcon-${row.id}`}
                                    icon={['fas', 'power-off']}
                                    size='1x'
                                />
                            </StatusIcon>
                        </Tooltip>
                        {row.genericTemperatureAlert ? (
                            <Tooltip title={translate('t.temperature_alert')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                                <StatusIcon
                                    $hide={!vehicleAlerts.temperature && row.genericTemperatureAlert}
                                    onClick={(event) => showModalAlerts(event, row.id, 0)}
                                >
                                    <UiIcon
                                        icon={faThermometerThreeQuarters}
                                        fixedWidth
                                        data-testid={`vehiclestats-temperatureAlertIcon-${row.id}`}
                                        size='1x'
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            dark: WHITE,
                                            light: '#FF1616'
                                        })}
                                    />
                                </StatusIcon>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        {!row.genericTemperatureAlert ? (
                            <Tooltip
                                title={translate('t.temperature_alert_disabled')}
                                aria-label={NO_ARIA_LABEL_ALLOWED}
                            >
                                <StatusIcon $hide={!vehicleAlerts.temperature && row.genericTemperatureAlert}>
                                    <span className='fa-layers fa-fw'>
                                        <UiIcon
                                            icon={faSlashRegular}
                                            data-testid={`vehiclestats-faThermometerThreeQuartersSlashIcon-${row.id}`}
                                            size='1x'
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: '#787878'
                                            })}
                                        />
                                        <UiIcon
                                            icon={faThermometerThreeQuarters}
                                            data-testid={`vehiclestats-faThermometerThreeQuartersIcon-${row.id}`}
                                            fixedWidth
                                            size='1x'
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: '#787878'
                                            })}
                                        />
                                    </span>
                                </StatusIcon>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        <StatusIcon
                            onClick={(event) => vehicleAlerts.highPressure && showModalAlerts(event, row.id, 0)}
                            $hide={!vehicleAlerts.highPressure}
                            $pressureTemperatureIcon
                            $display='flex'
                        >
                            <Tooltip title={translate('t.high_pressure_alert')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                                <UiHighPressureIcon
                                    color='#FFF'
                                    data-testid={`vehicleStats-highpressureIcon-${row.id}`}
                                />
                            </Tooltip>
                        </StatusIcon>
                        <StatusIcon
                            onClick={(event) => vehicleAlerts.lowPressure && showModalAlerts(event, row.id, 0)}
                            $hide={!vehicleAlerts.lowPressure}
                            $pressureTemperatureIcon
                            $display='flex'
                        >
                            <Tooltip title={translate('t.low_pressure_alert')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                                <UiLowPressureIcon data-testid={`vehiclestats-lowPressureIcon-${row.id}`} />
                            </Tooltip>
                        </StatusIcon>
                        <Tooltip title={translate('t.temperature_prediction')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                            <StatusIcon
                                onClick={(event) =>
                                    vehicleAlerts.temperaturePrediction && showModalAlerts(event, row.id, 0)
                                }
                                $hide={!vehicleAlerts.temperaturePrediction}
                            >
                                <UiIcon
                                    data-testid={`vehiclestats-temperaturePredictionIcon-${row.id}`}
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: SECONDARY_DARK_MODE,
                                        dark: WHITE
                                    })}
                                    icon={faChartMixedUpCircleCurrency}
                                    size='1x'
                                />
                            </StatusIcon>
                        </Tooltip>
                        <StatusIcon
                            onClick={(event) =>
                                vehicleAlerts.temperaturePrediction && showModalAlerts(event, row.id, 0)
                            }
                            $hide={!row.predictionTemperature}
                        >
                            <Tooltip title={translate('t.temperature_prediction')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                                <TemperaturePredictionPeriodIcon
                                    testid={`vehiclestats-temperaturePredictionPeriod-${row.id}`}
                                    time={row.lastTemperaturePredictedAt}
                                    predictionTemperature={row.predictionTemperature}
                                    wheels={row.wheels}
                                />
                            </Tooltip>
                        </StatusIcon>
                        <Tooltip title={translate('t.system_warnings')} aria-label={NO_ARIA_LABEL_ALLOWED}>
                            <StatusIcon
                                onClick={(event) => hasSystemAlert && showModalAlerts(event, row.id, 3)}
                                $hide={!hasSystemAlert}
                            >
                                <UiIcon
                                    data-testid={`vehiclestats-systemAlertsIcon-${row.id}`}
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: SECONDARY_DARK_MODE
                                    })}
                                    icon={faTriangleExclamation}
                                    size='1x'
                                />
                            </StatusIcon>
                        </Tooltip>
                        <StatusIcon onClick={(event) => showModalAlerts(event, row.id, 3)}>
                            <GpsIndicator
                                testid={`vehiclestats-gpsIndicator-${row.id}`}
                                powerSource={row.vehicleInfo.powerSource}
                                deviceId={null}
                                lastGpsMeasuredAt={DateTime.fromISO(row.vehicleInfo.lastGpsMeasuredAt)}
                                maintenance={Boolean(row.maintenance)}
                                outOfService={Boolean(row.outOfService)}
                                measuredAt={DateTime.fromISO(row.vehicleInfo.measuredAt)}
                            />
                        </StatusIcon>
                        {/* Temporary disabled for BE */}
                        {/* <StatusIcon $hide={!(row?.parkingTime > 0)}>
                            <FontAwesomeIcon
                                color={applyStyleByMode({
                                    styleOld: '#000000',
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                                icon={['fas', 'parking']}
                                size='1x'
                            />
                        </StatusIcon> */}
                    </Container>
                </TableCell>
                {customerSettings.mdd_bes && (
                    <TableCell title='' data-testid={`vehicleStats-row-operated-hours-BESRank-${row.id}`}>
                        {!!row.maxBesSeverityRankTotal && (
                            <BESRank maxBesSeverityRankTotal={row.maxBesSeverityRankTotal} vehicleId={row.id} />
                        )}
                    </TableCell>
                )}
                <TableCell
                    onClick={collapseRow}
                    data-testid={`vehicleStats-row-operated-hours-alerts-in-the-last-${row.id}`}
                    align='right'
                    title={`${alertDetailData.totalValues.current} ${translate(
                        't.alerts_in_the_last'
                    )} ${period} ${translate('t.hours')}`}
                >
                    {alertDetailData.totalValues.current}
                </TableCell>
                <TableCell
                    onClick={collapseRow}
                    data-testid={`vehicleStats-row-operated-hours-alerts-previously-to-${row.id}`}
                    align='right'
                    title={`${alertDetailData.totalValues.previous} ${translate(
                        't.alerts_previously_to'
                    )} ${period} ${translate('t.hours')}`}
                >
                    {alertDetailData.totalValues.previous}
                </TableCell>

                <UITableCell
                    onClick={collapseRow}
                    $align='right'
                    data-testid={`vehicleStats-row-operated-hours-distance-${row.id}`}
                >
                    {fromServerToUserUnit({
                        type: convertType.altitude,
                        value: row.odometer ?? 0,
                        displayUnits: true,
                        fixed: 1,
                        toType: convertType.distance
                    })}
                </UITableCell>
            </StyledTableRow>
            <TableRow>
                <TdSubTable colSpan={9}>
                    <VehicleStatsCollapseTable vehicleId={row.id} alertDetailData={alertDetailData} />
                </TdSubTable>
            </TableRow>
        </>
    );
};
