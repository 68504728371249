import React, { useCallback, useEffect, useReducer } from 'react';
import { TemperaturePressureListContent } from './TemperaturePressureTableList.view';
import { TemperaturePressureActionsEnum, TemperaturePressureListProps } from './TemperaturePressureTableList.type';
import { InicialStateTemperaturePressure, TemperaturePressureReducer } from './TemperaturePressureTableList.reducer';
import Tyre from 'api/Tyre';
import { useQuery } from '@tanstack/react-query';
import { TyreQueryKeys, TyreTempPressReport, TyreTempPressReportData } from 'models/Tyre.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ContainerNoData } from './TemperaturePressureTableList.style';
import { AlertLevelEnum, TableViewTypeActionsEnum } from '../TemperaturePressureTable/TemperaturePressureTable.type';
import { UserInfo } from 'states/global/User';
import { TempPressFilters } from '../TemperaturePressureTable/TempPressFilters.atom';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { Wrapper } from 'helpers/wrapper';
import { DateTime } from 'luxon';
import { isVehicleTyreBayHub } from 'helpers';

const apiTyre = new Tyre();

const TemperaturePressureList: React.FC<TemperaturePressureListProps> = ({
    tableDisplay,
    valueInputSearch,
    refresh,
    filterByAlert,
    showScroll,
    filterVehicleGroup,
    handleRefresh,
    setFilterByAlert,
    setTempPredServiceProblem
}): JSX.Element => {
    const [tempAndPressState, dispatchTempAndPressState] = useReducer(
        TemperaturePressureReducer,
        InicialStateTemperaturePressure
    );
    const [tempPressFiltersAtom, setTempPressFiltersAtom] = useRecoilState(TempPressFilters);
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const customerSettings = useRecoilValue(CustomerSettings);

    const {
        data: tempAndPressData,
        refetch: tempAndPressRefetch,
        isFetched: isLoading
    } = useQuery<TyreTempPressReport[], Error, TyreTempPressReportData>(
        [TyreQueryKeys.getTempPressReport],
        () => apiTyre.getTempPressReport(),
        {
            refetchOnWindowFocus: false,
            refetchInterval: valueInputSearch.length ? false : 30000,
            retry: 1,
            staleTime: 0,
            cacheTime: 0,
            retryDelay: 1000,
            onSuccess: (dataOnSuccess) => handleQuerySuccess(dataOnSuccess)
        }
    );

    const handleQuerySuccess = (response: TyreTempPressReportData) => {
        let orderedDataTempAndPress: TyreTempPressReport[] = response
            ? parseData(parseTyreByHubAndOutOfService(response.tyreTempPressReport))
            : [];

        if (response?.tyreTempPressReport) {
            orderedDataTempAndPress = handleQueryOrderDirection(orderedDataTempAndPress);
            if (filterByAlert) {
                orderedDataTempAndPress = handleFilterByAlert(orderedDataTempAndPress, filterByAlert);
            }
            if (filterVehicleGroup.value) {
                orderedDataTempAndPress = handleQueryFilterByVehicleGroup(orderedDataTempAndPress);
            }

            dispatchTempAndPressState({
                type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST,
                payload: orderedDataTempAndPress
            });

            if (!valueInputSearch.length) {
                dispatchTempAndPressState({ type: TemperaturePressureActionsEnum.SET_LOADING, payload: false });
            }

            if (customerSettings.temperature_prediction) {
                validateTemperaturePredictionService(response.tyreTempPressReport);
            }
        } else {
            dispatchTempAndPressState({ type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST, payload: [] });
        }
    };

    const parseTyreByHubAndOutOfService = (data: TyreTempPressReport[]): TyreTempPressReport[] => {
        return userInfo.user?.userSetting.showOutOfService
            ? data.filter((vehicle) => !isVehicleTyreBayHub(vehicle.vehicleModelId))
            : data.filter((vehicle) => vehicle.outOfService === 0 && !isVehicleTyreBayHub(vehicle.vehicleModelId));
    };

    const handleQueryOrderDirection = (orderedDataTempAndPress: TyreTempPressReport[]): TyreTempPressReport[] => {
        if (tempAndPressState.orderDirection) {
            const { column, order } = tempAndPressState.orderDirection;
            if (column === 'severity_of_alert' || column === 'severity+name') {
                return sortTemPressListCustom(orderedDataTempAndPress, order, false, column === 'severity+name');
            } else {
                return sortTemPressListByLabel(orderedDataTempAndPress, order, column, column === 'lastTransmission');
            }
        }
        return orderedDataTempAndPress;
    };

    const handleQueryFilterByVehicleGroup = (data: TyreTempPressReport[]): TyreTempPressReport[] => {
        return data.filter((vehicle) => vehicle.vehicleGroupId === filterVehicleGroup.value);
    };

    const validateTemperaturePredictionService = (tyreTempPressReport: TyreTempPressReport[]) => {
        let isWrong = false;
        for (const vehicle of tyreTempPressReport) {
            for (const wheel of vehicle.wheels) {
                if (wheel.lastTemperaturePredictedAt) {
                    const tempPredTime = DateTime.fromISO(wheel.lastTemperaturePredictedAt, { zone: 'UTC' });
                    const currentTime = DateTime.now().setZone('UTC');
                    const diff = currentTime.diff(tempPredTime, 'minutes').minutes;

                    if (diff <= 6) {
                        setTempPredServiceProblem(false);
                        return;
                    }
                    isWrong = true;
                }
            }
        }
        setTempPredServiceProblem(isWrong);
    };

    const sortTemPressListByLabel = (vehicles, orderBy, column, date = false): TyreTempPressReport[] => {
        const compareValues = (a, b) =>
            date
                ? new Date(a[column]).getTime() - new Date(b[column]).getTime()
                : a[column] > b[column]
                ? 1
                : a[column] < b[column]
                ? -1
                : 0;

        return vehicles.sort((vehicle, newVehicle) => {
            const comparison = compareValues(vehicle, newVehicle);
            return orderBy === 'asc' ? comparison : -comparison;
        });
    };

    const updateHighestAlertLevel = (wheelLevel, highestAlertLevel, orderBy) =>
        orderBy === 'asc' ? Math.min(wheelLevel, highestAlertLevel) : Math.max(wheelLevel, highestAlertLevel);

    const countAlertLevels = (wheelLevel, countAlertLevel, orderBy) =>
        orderBy !== 'asc' && wheelLevel === 3 ? countAlertLevel + 1 : countAlertLevel;

    const sortTemPressListCustom = (vehicles, orderBy, resetAlertLevelFilter = false, severityNameSorting = false) => {
        vehicles = vehicles.map((listWheel) => {
            let highestAlertLevel = orderBy === 'asc' ? 8 : 0;
            let countAlertLevelLow = 0;
            let countAlertLevel = 0;

            if (resetAlertLevelFilter) setFilterByAlert(AlertLevelEnum.ALERT_LEVEL_0);

            listWheel.wheels.forEach((wheel) => {
                highestAlertLevel = updateHighestAlertLevel(wheel.pressureLevel, highestAlertLevel, orderBy);
                countAlertLevel = countAlertLevels(wheel.pressureLevel, countAlertLevel, orderBy);

                highestAlertLevel = updateHighestAlertLevel(wheel.pressureHotLevel, highestAlertLevel, orderBy);
                countAlertLevel = countAlertLevels(wheel.pressureHotLevel, countAlertLevel, orderBy);

                highestAlertLevel = updateHighestAlertLevel(wheel.temperatureLevel, highestAlertLevel, orderBy);
                countAlertLevel = countAlertLevels(wheel.temperatureLevel, countAlertLevel, orderBy);

                if (orderBy === 'asc') {
                    countAlertLevelLow += [wheel.pressureLevel, wheel.pressureHotLevel, wheel.temperatureLevel].some(
                        (level) => level < highestAlertLevel
                    )
                        ? 1
                        : 0;
                }
            });

            return {
                ...listWheel,
                wheelFilterByAlert: highestAlertLevel,
                countAlertLevel,
                countAlertLevelLow
            };
        });

        if (orderBy === 'asc') {
            vehicles.sort((a, b) => a.countAlertLevelLow - b.countAlertLevelLow);
            vehicles.sort((a, b) => a.wheelFilterByAlert - b.wheelFilterByAlert);
        } else {
            vehicles.sort((a, b) => b.countAlertLevel - a.countAlertLevel);
            vehicles.sort((a, b) => b.wheelFilterByAlert - a.wheelFilterByAlert);

            if (severityNameSorting) {
                vehicles.sort((a, b) => b.wheelFilterByAlert - a.wheelFilterByAlert || a.name.localeCompare(b.name));
            }
        }

        return vehicles;
    };

    const handleSortRequest = (
        column,
        order,
        date = false,
        custom = false,
        severityNameSorting = false,
        sort = false
    ) => {
        dispatchTempAndPressState({
            type: TemperaturePressureActionsEnum.SET_ORDER_DIRECTION,
            payload: { column, order }
        });

        if (order) {
            dispatchTempAndPressState({
                type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST,
                payload: custom
                    ? sortTemPressListCustom(tempAndPressState.vehiclesList, order, true, severityNameSorting)
                    : sortTemPressListByLabel(tempAndPressState.vehiclesList, order, column, date)
            });
        }

        if (!sort) {
            setTempPressFiltersAtom({ ...tempPressFiltersAtom, sortBy: null });
        }
    };

    const parseData = useCallback(
        (data) => {
            if (data.length) {
                let maxPosition = 0;

                const newData = data.map((d) => {
                    const updatedWheels = d.wheels.map((wheel) => {
                        if (wheel.customPosition > +maxPosition && wheel.status) {
                            maxPosition = wheel.customPosition;
                        }
                        return {
                            ...wheel,
                            hotPressureOptimal: customerSettings.wet_tyres
                                ? wheel.hotPressureOptimalWet
                                : wheel.hotPressureOptimal,
                            pressureLevel: wheel.coldPressure && wheel.hotPressure ? wheel.pressureLevel : null,
                            pressureHotLevel: wheel.coldPressure && wheel.hotPressure ? wheel.pressureHotLevel : null,
                            temperatureLevel: wheel.temperature ? wheel.temperatureLevel : null
                        };
                    });

                    return { ...d, wheels: updatedWheels };
                });

                if (maxPosition > tempAndPressState.maxPosition) {
                    dispatchTempAndPressState({
                        type: TemperaturePressureActionsEnum.SET_MAX_POSITION,
                        payload: maxPosition
                    });
                }

                return newData;
            }
            return data;
        },
        [tempAndPressState.maxPosition, customerSettings.wet_tyres]
    );

    const handleSearchVehicle = useCallback(
        (data) => {
            if (valueInputSearch.length >= 1 && data) {
                const newList = data.filter(
                    (row) =>
                        row.name.toLowerCase().includes(valueInputSearch.toLowerCase()) ||
                        (row?.deviceId && row?.deviceId.toString().includes(valueInputSearch.toLowerCase()))
                );

                if (tempAndPressState.orderDirection?.column === 'severity_of_alert') {
                    return sortTemPressListCustom(
                        handleFilterByAlert(parseData(newList), filterByAlert),
                        tempAndPressState.orderDirection.column
                    );
                }

                return handleFilterByAlert(parseData(newList), filterByAlert);
            }

            if (data?.length) {
                if (tempAndPressState.orderDirection?.column === 'severity_of_alert') {
                    return sortTemPressListCustom(
                        handleFilterByAlert(parseData(data), filterByAlert),
                        tempAndPressState.orderDirection.column
                    );
                }

                return handleFilterByAlert(parseData(data), filterByAlert);
            }

            return [];
        },
        [valueInputSearch, parseData, filterByAlert]
    );

    const handleFilterByAlert = (data: TyreTempPressReport[], filterByAlert: number): TyreTempPressReport[] => {
        if (!filterByAlert) {
            if (tempAndPressState.orderDirection?.column === 'severity_of_alert') {
                return sortTemPressListCustom(data, tempAndPressState.orderDirection.column);
            }
            return data;
        }
        const filteredData = data.map((listWheel) => {
            const existAlert = listWheel.wheels.some(
                (wheel) =>
                    wheel.pressureLevel === filterByAlert ||
                    wheel.pressureHotLevel === filterByAlert ||
                    wheel.temperatureLevel === filterByAlert
            );
            if (existAlert) {
                return { ...listWheel, wheelFilterByAlert: filterByAlert };
            }
            return listWheel;
        });
        const result = filteredData.filter((listWheel) => listWheel.wheelFilterByAlert === filterByAlert);
        if (tempAndPressState.orderDirection?.column === 'severity_of_alert') {
            return sortTemPressListCustom(result, tempAndPressState.orderDirection.column);
        }
        return result;
    };

    const containsRecomValues = (): boolean => {
        return [TableViewTypeActionsEnum.HOT_OPT as string, TableViewTypeActionsEnum.COLD_OPT as string].includes(
            tableDisplay
        );
    };

    useEffect(() => {
        dispatchTempAndPressState({
            type: TemperaturePressureActionsEnum.SET_WET_TYRES,
            payload: customerSettings.wet_tyres
        });
    }, [customerSettings.wet_tyres]);

    useEffect(() => {
        if (tempAndPressData && !!tempAndPressData.tyreTempPressReport.length) {
            dispatchTempAndPressState({
                type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST,
                payload: handleSearchVehicle(parseTyreByHubAndOutOfService(tempAndPressData.tyreTempPressReport))
            });
        }
    }, [handleSearchVehicle]);

    useEffect(() => {
        if (tempAndPressData && !!tempAndPressData.tyreTempPressReport.length) {
            tempAndPressRefetch();
            if (isLoading) {
                handleRefresh(true);
            }
        }
    }, [filterByAlert, filterVehicleGroup]);

    useEffect(() => {
        if (refresh) {
            dispatchTempAndPressState({ type: TemperaturePressureActionsEnum.SET_LOADING, payload: true });
            tempAndPressRefetch();
            if (isLoading) {
                handleRefresh(false);
                !!valueInputSearch.length &&
                    dispatchTempAndPressState({ type: TemperaturePressureActionsEnum.SET_LOADING, payload: false });
            }
        }
    }, [refresh, isLoading, handleRefresh, tempAndPressRefetch, valueInputSearch]);

    useEffect(() => {
        if (tempPressFiltersAtom.sortBy) {
            if (tempPressFiltersAtom.sortBy.column === 'severity+name') {
                handleSortRequest('severity+name', 'desc', false, true, true, true);
            } else {
                handleSortRequest(
                    tempPressFiltersAtom.sortBy.column,
                    tempPressFiltersAtom.sortBy.order,
                    false,
                    tempPressFiltersAtom.sortBy.column === 'severity_of_alert',
                    false,
                    true
                );
            }
        }
    }, [tempPressFiltersAtom.sortBy]);

    return tempAndPressState.loandingContent ? (
        <UiLoadingPage size='30px' testid='TemperaturePressureList-loading' />
    ) : tempAndPressState.vehiclesList && tempAndPressState.vehiclesList.length ? (
        <TemperaturePressureListContent
            data-testid='TemperaturePressureList-testid'
            tableDisplay={tableDisplay}
            containsRecomValues={containsRecomValues}
            vehiclesList={tempAndPressState.vehiclesList}
            orderDirection={tempAndPressState.orderDirection}
            handleSortRequest={handleSortRequest}
            maxPosition={tempAndPressState.maxPosition}
            showScroll={showScroll}
        />
    ) : (
        <ContainerNoData>
            <h3>{translate('t.there_no_data')}</h3>
        </ContainerNoData>
    );
};

export default Wrapper(TemperaturePressureList);
